const INITIAL_STATE = {
  importando: false,
  cadastrando: false,
  cadastroSucesso: false
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'IMPORTAR_REQUEST': {
      return {
        ...state,
        importando: true
      }
    }
    case 'IMPORTAR_ERROR':
    case 'IMPORTAR_SUCCESS': {
      return {
        ...state,
        importando: false
      }
    }
    case 'CADASTRORAPIDO_REQUEST': {
      return {
        ...state,
        cadastrando: true,
        cadastroSucesso: false
      }
    }
    case 'CADASTRORAPIDO_SUCCESS': {
      return {
        ...state,
        cadastrando: false,
        cadastroSucesso: true
      }
    }
    case 'CADASTRORAPIDO_ERROR': {
      return {
        ...state,
        cadastrando: false,
        cadastroSucesso: false
      }
    }
    default: {
      return state
    }
  }
}