import React, { Component } from 'react'
import './style.css'


class Rodape extends Component {
  render() {
    return (
      <footer className="rodape border-top py-2 px-4">
        <span>Versão v0.0.1</span>
      </footer>
    )
  }
}

export default Rodape