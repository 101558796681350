import React, { Component } from 'react'
import './style.css'

import { Container } from 'react-bootstrap'
import Cabecalho from '../Cabecalho'
import Rodape from '../Rodape'

class Dashboard extends Component {
  render() {
    return (
      <Container fluid className="dashboard">
        <Cabecalho></Cabecalho>
        {this.props.children}
        <Rodape></Rodape>
      </Container>
    )
  }
}

export default Dashboard