import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import reducers from '../reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [ 'login' ]
}

const persistedReducer = persistReducer(persistConfig, reducers)

let store = createStore(persistedReducer, applyMiddleware(thunk));
let persistor = persistStore(store)

export { store, persistor }
