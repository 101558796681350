import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './style.css'
import imgLogoIcon from '../../images/logo-icon.png'
import imgLogoText from '../../images/logo-text.png'
import Rodape from '../../components/Rodape'
import {
  Container, Row, Col, Form, FormGroup, FormLabel, Button, Alert
} from 'react-bootstrap'
import { logar } from '../../actions/LoginAction'
import Loading from '../../components/Loading'


class Login extends Component {

  state = {
    inputs : {
      usuario: '',
      senha: ''
    },
    isFailure: false
  }

  apagarCampos = () => {
    let inputs = {...this.state.inputs}
    inputs.senha = ''
    inputs.usuario = ''
    this.setState({inputs})
  }

  usuarioOnChange = e => {
    if (this.props.login.isFailure) {
      this.props.login.isFailure = false
    }
    let inputs = {...this.state.inputs}
    inputs.usuario = e.target.value
    this.setState({inputs})
  }

  senhaOnChange = e => {
    if (this.props.login.isFailure) {
      this.props.login.isFailure = false
    }
    let inputs = {...this.state.inputs}
    inputs.senha = e.target.value
    this.setState({inputs})
  }

  submit = e => {
    e.preventDefault()
    const { usuario, senha } = this.state.inputs
    this.props.logar(usuario, senha)
    this.apagarCampos()
  }

  render() {
    if (this.props.login.loadLogin) {
      return (
        <Loading></Loading>
      )
    }
    return (
      <Container fluid className="login">
        <Row className="login-card row justify-content-center align-items-center">
          <Col>
            <div className="text-center">
              <img
                alt=""
                src={imgLogoIcon}
                height="80"
              />
            </div>
            <div className="text-center">
              <img
                alt=""
                src={imgLogoText}
                height="80"
              />
            </div>
            <Form onSubmit={this.submit} className="login-form p-4">
              <FormGroup>
                <FormLabel className="login-label" htmlFor="usuario">Login:</FormLabel><br />
                <input type="text" name="usuario" id="usuario" className="form-control" value={this.state.inputs.usuario} onChange={this.usuarioOnChange} />
              </FormGroup>
              <FormGroup>
                <FormLabel className="login-label" htmlFor="senha">Senha:</FormLabel><br />
                <input type="password" name="senha" id="senha" className="form-control" value={this.state.inputs.senha} onChange={this.senhaOnChange} />
              </FormGroup>
              <FormGroup>
                <Button className="btn-login" type="submit" name="submit" variant="primary" >Entrar</Button>
              </FormGroup>
              {
                this.props.login.isFailure
                  ? <Alert variant='danger'>Erro ao tentar logar.</Alert>
                  : null
              }
            </Form>
          </Col>
        </Row>
        <Rodape></Rodape>
      </Container>
    )
  }
}

const mapStateToProps = state =>
  ({
    login: state.login
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ logar }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Login)