import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateUsuario, getUsuario } from "../../actions/UsuariosAction";
import Loading from "../../components/Loading";
import CabecalhoInterno from "../../components/CabecalhoInterno";
import ContainerInterno from "../../components/ContainerInterno";
import { Form, Col, Row, Button, Alert } from "react-bootstrap";
import { Redirect } from "react-router";

class UsuariosEdit extends Component {
  state = {
    formUsuario: "",
    formEmail: "",
    formNome: "",
    formNivel: "",
    formSenha: "",
    formPerfil: "",
    formSenhaConfirm: "",
    erro: null,
    erroShow: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getUsuario(this.props.login.accessToken, id);
  }

  setError = (errorMessage = "Erro ao tentar criar o usuário.") => {
    this.setState({
      erro: errorMessage,
      erroShow: true,
    });
    document.getElementsByClassName("containerInterno")[0].scrollTop = 0;
    return false;
  };

  formSubmit = (e) => {
    e.preventDefault();
    const { usuario } = this.props.usuarios;
    const { id } = this.props.match.params;
    const {
      formUsuario,
      formEmail,
      formNome,
      formNivel,
      formPerfil,
    } = this.state;
    var errorMessage = "";
    if (!formUsuario && !usuario.usuario) {
      errorMessage += "* Preencha o usuário.<br />";
    }
    if (!formEmail && !usuario.email) {
      errorMessage += "* Preencha o e-mail.<br />";
    }
    if (!formNome && !usuario.nome) {
      errorMessage += "* Preencha o nome.<br />";
    }
    if (errorMessage !== "") {
      return this.setError(errorMessage);
    }
    /*const body = {
      usuario: formUsuario ? formUsuario : usuario.usuario,
      nome: formNome ? formNome : usuario.nome,
      email: formEmail ? formEmail : usuario.email,
      nivel_de_acesso: formNivel ? formNivel : usuario.nivel_de_acesso,
      perfil: formPerfil ? formPerfil : usuario.perfil,
    };*/
    const body = {
      usuario: formUsuario !== usuario.usuario ? formUsuario : null,
      nome: formNome !== usuario.nome ? formNome : null,
      email: formEmail !== usuario.email ? formEmail : null,
      nivel_de_acesso: formNivel ? formNivel : usuario.nivel_de_acesso,
      perfil: formPerfil ? formPerfil : usuario.perfil,
    };
    this.props.updateUsuario(this.props.login.accessToken, id, body);
  };

  render() {
    const {
      formUsuario,
      formEmail,
      formNome,
      formNivel,
      formSenha,
      formSenhaConfirm,
      formPerfil,
      erro,
      erroShow,
    } = this.state;
    const { redirectTo } = this.props.redirect;
    const { usuario, loadUsuarios } = this.props.usuarios;

    if (loadUsuarios || !usuario) {
      return <Loading></Loading>;
    }
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    const niveis = ["usuario", "administrador"];

    return (
      <div>
        <CabecalhoInterno history={this.props.history}>
          <h3>Editar usuário</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Form onSubmit={this.formSubmit}>
            {erro && erroShow ? (
              <Alert
                variant="danger"
                onClick={() => this.setState({ erroShow: false })}
              >
                <div dangerouslySetInnerHTML={{ __html: erro }} />
              </Alert>
            ) : (
              []
            )}
            <Form.Group as={Row} controlId="formUsuario">
              <Form.Label column sm="1" className="text-right">
                Usuário
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formUsuario ? formUsuario : usuario.usuario}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      formUsuario: e.target.value,
                      erroShow: false,
                    })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formEmail">
              <Form.Label column sm="1" className="text-right">
                E-mail
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formEmail ? formEmail : usuario.email}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      formEmail: e.target.value,
                      erroShow: false,
                    })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formNome">
              <Form.Label column sm="1" className="text-right">
                Nome
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formNome ? formNome : usuario.nome}
                  type="text"
                  onChange={(e) =>
                    this.setState({ formNome: e.target.value, erroShow: false })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPerfil">
              <Form.Label column sm="1" className="text-right">
                Perfil
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  as="select"
                  value={formPerfil ? formPerfil : usuario.perfil}
                  onChange={(e) =>
                    this.setState({
                      formPerfil: e.target.value,
                      erroShow: false,
                    })
                  }
                >
                  <option value="ESTUDANTE" key="1">
                    Estudante
                  </option>
                  <option value="PROFESSOR" key="2">
                    Professor
                  </option>
                  <option value="PROFISSIONAL" key="3">
                    Profissional de Direito
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formMateria">
              <Form.Label column sm="1" className="text-right">
                Nível de acesso
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  as="select"
                  value={formNivel ? formNivel : usuario.nivel_de_acesso}
                  onChange={(e) =>
                    this.setState({
                      formNivel: e.target.value,
                      erroShow: false,
                    })
                  }
                >
                  <option value="0" key="0">
                    -- Selecione uma nível de acesso --
                  </option>
                  {niveis.map((nivel) => (
                    <option value={nivel} key={nivel}>
                      {nivel}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formSenha">
              <Form.Label column sm="1" className="text-right">
                Nova senha
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formSenha}
                  type="password"
                  onChange={(e) =>
                    this.setState({
                      formSenha: e.target.value,
                      erroShow: false,
                    })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formSenhaConfirm">
              <Form.Label column sm="1" className="text-right">
                Confirme a nova senha
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formSenhaConfirm}
                  type="password"
                  onChange={(e) =>
                    this.setState({
                      formSenhaConfirm: e.target.value,
                      erroShow: false,
                    })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm={{ span: 11, offset: 1 }}>
                <Button type="submit">Editar</Button>
              </Col>
            </Form.Group>
          </Form>
        </ContainerInterno>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usuarios: state.usuarios,
  login: state.login,
  redirect: state.redirect,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateUsuario, getUsuario }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsuariosEdit);
