import Swal from 'sweetalert2'
import { API_URL } from '../configs/config'

export const getBanca = (id) => {
  return dispatch => {
    dispatch({ type: 'GET_BANCA_REQUEST' })
    fetch(`${API_URL}/banca/${id}`)
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'GET_BANCA_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'GET_BANCA_FAILURE',
        payload: error
      }))
  }
}

export const getBancas = () => {
  return dispatch => {
    dispatch({ type: 'GET_BANCAS_REQUEST' })
    fetch(`${API_URL}/banca`)
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'GET_BANCAS_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'GET_BANCAS_FAILURE',
        payload: error
      }))
  }
}

export const storeBanca = (accessToken, opts = {}) => {

  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })

  const body = JSON.stringify(opts)

  return dispatch => {
    dispatch({ type: 'STORE_BANCA_REQUEST' })
    fetch(`${API_URL}/banca`, {
      method: 'post',
      headers,
      body,
    })
      .then(T => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error('erro')
        }
        return T.json()
      })
      .then(response => {
        Swal.fire({
          title: 'Banca criada com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'STORE_BANCA_SUCCESS',
          payload: response
        })
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao tentar salvar a Banca.',
          type: 'error',
          confirmButtonColor: '#dc3545',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'STORE_BANCA_FAILURE',
          payload: error
        })
      })
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/bancas'
        })
      })
  }
}

export const updateBanca = (accessToken, id, opts = {}) => {
  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })
  const body = JSON.stringify(opts)
  return dispatch => {
    dispatch({ type: 'UPDATE_BANCA_REQUEST' })
    fetch(`${API_URL}/banca/${id}`, {
      method: 'put',
      headers,
      body,
    })
      .then(T => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error('erro')
        }
        return T.json()
      })
      .then(response => {
        Swal.fire({
          title: 'Banca editada com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'UPDATE_BANCA_SUCCESS',
          payload: response
        })
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao tentar editar a Banca.',
          type: 'error',
          confirmButtonColor: '#dc3545',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'UPDATE_BANCA_FAILURE',
          payload: error
        })
      })
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/bancas'
        })
      })
  }
}

export const deleteBancas= (accessToken, ids = null) => {
  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })
  return dispatch => {
    dispatch({ type: 'DELETE_BANCA_REQUEST' })
    fetch(`${API_URL}/banca/${ids}`, {
      method: 'delete',
      headers
    })
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'DELETE_BANCA_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'DELETE_BANCA_FAILURE',
        payload: error
      }))
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/bancas'
        })
        Swal.fire({
          title: 'Banca(s) removida(s) com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        }).then(e => {
          window.location.reload();
        })
      })
  }
}