import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CabecalhoInterno from "../../components/CabecalhoInterno";
import ContainerInterno from "../../components/ContainerInterno";
import { Row, Col, Button, Form } from "react-bootstrap";
import "./style.css";
import { cadastrorapido } from "../../actions/ImportarAction";

import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";

class CadastroRapido extends Component {
  state = {
    grid: [],
    rows: 10,

    formBanca: null,
    formCargo: null,
    formOrgao: null,
    formUF: "NACIONAL",
    formConcurso: null,
    formAno: null,
    formTipo: "múltipla escolha 5",
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount = () => {
    var grid = this.getGrid(this.state.rows, 9);
    this.setState({ grid: grid });
  };

  onChange = (cell, row, col, value) => {
    var grid = this.state.grid;
    grid[row][col] = { ...grid[row][col], value };
    this.setState({ grid });
  };

  getGrid = (rows, cols) => {
    //const state = this.state;

    var grid = [];
    for (let i = 0; i < rows; i++) {
      var fields = [
        //Matéria
        {
          value: this.state.grid[i] ? this.state.grid[i][0].value : "",
        },
        //Assunto
        {
          value: this.state.grid[i] ? this.state.grid[i][1].value : "",
        },
        //Enunciado
        {
          value: this.state.grid[i] ? this.state.grid[i][2].value : "",
          component: (
            <textarea
              data-col={2}
              data-row={i}
              onBlur={(t) => {
                this.onChange(
                  {},
                  t.target.getAttribute("data-row"),
                  t.target.getAttribute("data-col"),
                  t.target.value
                );
              }}
            >
              {this.state.grid[i] ? this.state.grid[i][2].value : ""}
            </textarea>
          ),
          forceComponent: true,
        },
        //Resposta a
        {
          value: this.state.grid[i] ? this.state.grid[i][3].value : "",
        },
        //Resposta b
        {
          value: this.state.grid[i] ? this.state.grid[i][4].value : "",
        },
        //Resposta c
        {
          value: this.state.grid[i] ? this.state.grid[i][5].value : "",
        },
        //Resposta d
        {
          value: this.state.grid[i] ? this.state.grid[i][6].value : "",
        },
        //Resposta e
        {
          value: this.state.grid[i] ? this.state.grid[i][7].value : "",
        },
        //Resposta correta
        {
          value: this.state.grid[i] ? this.state.grid[i][8].value : "",
          component: (
            <select
              style={{ width: 72, textAlign: "center" }}
              value={this.state.grid[i] ? this.state.grid[i][8].value : ""}
              data-col={8}
              data-row={i}
              onChange={(t) => {
                this.onChange(
                  {},
                  t.target.getAttribute("data-row"),
                  t.target.getAttribute("data-col"),
                  t.target.value
                );
              }}
            >
              <option value=""></option>
              <option value="a">a</option>
              <option value="b">b</option>
              <option value="c">c</option>
              <option value="d">d</option>
              <option value="e">e</option>
            </select>
          ),
          forceComponent: true,
        },
      ];
      grid.push(fields);
    }
    return grid;
  };

  addRow = () => {
    var { grid } = this.state;
    var fields = [];
    for (var j = 0; j < 9; j++) {
      fields.push({});
    }
    grid.push(fields);
    this.setState({ grid: grid });

    var rows = this.state.rows + 1;
    this.setState({ rows: rows });
  };

  renderChildrens = (props) => {
    return (
      <tr>
        <td className="action-cell">#{props.row + 1}</td>
        {props.children}
      </tr>
    );
  };

  cadastrar = () => {
    const { grid } = this.state;

    var questoes = grid
      .filter((row) => {
        if (
          !row[0].value &&
          !row[1].value &&
          !row[2].value &&
          !row[3].value &&
          !row[4].value &&
          !row[5].value &&
          !row[6].value &&
          !row[7].value &&
          !row[8].value
        ) {
          return false;
        } else {
          return true;
        }
      })
      .map((row) => {
        return {
          banca: this.state.formBanca,
          cargo: this.state.formCargo,
          orgao: this.state.formOrgao,
          uf: this.state.formUF,
          concurso: this.state.formConcurso,
          ano: this.state.formAno,
          tipo: this.state.formTipo,

          materia: row[0].value,
          assunto: row[1].value,
          enunciado: row[2].value,
          "resposta a": row[3].value,
          "resposta b": row[4].value,
          "resposta c": row[5].value,
          "resposta d": row[6].value,
          "resposta e": row[7].value,
          "resposta correta": row[8].value,
        };
      });

    this.props.cadastrorapido(this.props.login.accessToken, {
      questoes: questoes,
    });
  };

  render() {
    const columns = [
      { name: "", width: 40 },
      { name: "materia", width: 120 },
      { name: "assunto", width: 120 },
      { name: "enunciado", width: 320 },
      { name: "resposta a", width: 210 },
      { name: "resposta b", width: 210 },
      { name: "resposta c", width: 210 },
      { name: "resposta d", width: 210 },
      { name: "resposta e", width: 210 },
      { name: "resposta correta", width: 80 },
    ];

    const grid = this.getGrid(this.state.rows, 16);

    return (
      <div>
        <CabecalhoInterno>
          <h3>Cadastro Rápido</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Row className="mx-1">
            <Form.Group as={Row} controlId="formTipo">
              <Form.Label column sm="1" className="text-right">
                Tipo
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  as="select"
                  value={this.state.formTipo}
                  onChange={(e) =>
                    this.setState({ formTipo: e.target.value, erroShow: false })
                  }
                >
                  <option value="múltipla escolha 5">Múltipla escolha 5</option>
                  <option value="múltipla escolha 4">Múltipla escolha 4</option>
                  <option value="certo ou errado">Certo ou errado</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formBanca">
              <Form.Label column sm="1" className="text-right">
                Banca
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={this.state.formBanca}
                  type="text"
                  name="banca"
                  onChange={(e) =>
                    this.setState({
                      formBanca: e.target.value,
                      erroShow: false,
                    })
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formBanca">
              <Form.Label column sm="1" className="text-right">
                Cargo
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={this.state.formCargo}
                  type="text"
                  name="cargo"
                  onChange={(e) =>
                    this.setState({
                      formCargo: e.target.value,
                      erroShow: false,
                    })
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formBanca">
              <Form.Label column sm="1" className="text-right">
                Órgão
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={this.state.formOrgao}
                  type="text"
                  name="orgao"
                  onChange={(e) =>
                    this.setState({
                      formOrgao: e.target.value,
                      erroShow: false,
                    })
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formUF">
              <Form.Label column sm="1" className="text-right">
                UF
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={this.state.formUF}
                  type="text"
                  as="select"
                  name="UF"
                  onChange={(e) =>
                    this.setState({ formUF: e.target.value, erroShow: false })
                  }
                >
                  <option value="NACIONAL">Nível Nacional</option>
                  <option value="AC">AC</option>
                  <option value="AL">AL</option>
                  <option value="AM">AM</option>
                  <option value="AP">AP</option>
                  <option value="BA">BA</option>
                  <option value="CE">CE</option>
                  <option value="DF">DF</option>
                  <option value="ES">ES</option>
                  <option value="GO">GO</option>
                  <option value="MA">MA</option>
                  <option value="MG">MG</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="PA">PA</option>
                  <option value="PB">PB</option>
                  <option value="PE">PE</option>
                  <option value="PI">PI</option>
                  <option value="PR">PR</option>
                  <option value="RJ">RJ</option>
                  <option value="RN">RN</option>
                  <option value="RO">RO</option>
                  <option value="RR">RR</option>
                  <option value="RS">RS</option>
                  <option value="SC">SC</option>
                  <option value="SE">SE</option>
                  <option value="SP">SP</option>
                  <option value="TO">TO</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formConcurso">
              <Form.Label column sm="1" className="text-right">
                Concurso
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={this.state.formConcurso}
                  type="text"
                  name="concurso"
                  onChange={(e) =>
                    this.setState({
                      formConcurso: e.target.value,
                      erroShow: false,
                    })
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formAno">
              <Form.Label column sm="1" className="text-right">
                Ano
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={this.state.formAno}
                  type="number"
                  min="1900"
                  max="2020"
                  step="1"
                  onChange={(e) =>
                    this.setState({ formAno: e.target.value, erroShow: false })
                  }
                />
              </Col>
            </Form.Group>
          </Row>

          <Row>
            <Col className="tableq-container">
              <ReactDataSheet
                data={grid}
                valueRenderer={(cell) => cell.value}
                sheetRenderer={(props) => (
                  <table className="tableq">
                    <thead>
                      <tr>
                        {columns.map((col) => (
                          <th style={{ width: col.width }}>{col.name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>{props.children}</tbody>
                  </table>
                )}
                rowRenderer={(props) => {
                  return this.renderChildrens(props);
                }}
                onCellsChanged={(changes) => {
                  const grid = this.state.grid.map((row) => [...row]);
                  changes.forEach(({ cell, row, col, value }) => {
                    grid[row][col] = { ...grid[row][col], value };
                  });
                  this.setState({ grid });
                }}
                /*onSelect={
                  () => {
                    //scroll left right
                    if (
                      document.querySelector('.cell.selected').offsetLeft > document.querySelector('.tableq-container').offsetWidth
                    ) {
                      document.querySelector('.tableq-container').scrollLeft = document.querySelector('.cell.selected').offsetLeft - 50
                    } else {
                      document.querySelector('.tableq-container').scrollLeft = 0
                    }
                    //scroll up down
                    if (
                      document.querySelector('.cell.selected').offsetTop > document.querySelector('.tableq-container').offsetHeight - 50
                    ) {
                      document.querySelector('.tableq-container').scrollTop = document.querySelector('.cell.selected').offsetTop
                    } else {
                      document.querySelector('.tableq-container').scrollTop = 0
                    }
                  }
                }*/
              />
            </Col>
          </Row>
          {
            <Row>
              <Col>
                <Button
                  type="button"
                  variant="secondary"
                  size="sm"
                  className="py-1 px-2 my-2"
                  onClick={() => {
                    this.addRow();
                  }}
                >
                  Adicionar linha
                </Button>
              </Col>
            </Row>
          }

          <Row className="mt-2">
            <Col>
              <Button
                type="button"
                variant="primary"
                onClick={() => {
                  this.cadastrar();
                }}
              >
                Cadastrar questões
              </Button>
            </Col>
          </Row>
        </ContainerInterno>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ cadastrorapido }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CadastroRapido);
