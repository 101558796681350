import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getBancas, deleteBancas } from '../../actions/BancasAction'
import Loading from '../../components/Loading'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import { Table, Alert, Row, Col } from 'react-bootstrap'
import MenuTabela from '../../components/MenuTabela'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'

class Bancas extends Component {

  state = {
    bancas: [],
    bancasSelecionadas: []
  }

  componentDidMount() {
    this.props.getBancas()
  }

  selecionarBancas = e => {
    const id = e.target.value
    const bancasSelecionadas = { ...this.state.bancasSelecionadas, [id]: e.target.checked }
    this.setState({'bancasSelecionadas': bancasSelecionadas})
  }

  removerBancas = e => {
    const { bancasSelecionadas } = this.state
    var bancas = Object.keys(bancasSelecionadas).map((key) => {
      if (bancasSelecionadas[key]) {
        return key
      }
      return false
    })
    bancas = bancas.filter(Boolean)
    Swal.fire({
      title: `Deseja realmente remover a${bancas.length !== 1 ? 's' : ''} banca${bancas.length !== 1 ? 's' : ''}?`,
      text: `${bancas.length} selecionada${bancas.length  !== 1 ? 's':''}.`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#dc3545',
      focusCancel: true,
    }).then(e => {
      if (e.value) {
        this.props.deleteBancas(this.props.login.accessToken, bancas.join())
      }
    })
  }

  render() {
    const { bancasSelecionadas } = this.state
    const { bancas, loadBancas } = this.props.bancas

    if (loadBancas) {
      return (
        <Loading></Loading>
      )
    }
    var selecionado = Object.keys(bancasSelecionadas).find((key) => {
      return bancasSelecionadas[key]
    })
    return (
      <div>
        <CabecalhoInterno addUrl="/bancas/create" >
          <h3>Bancas</h3>
        </CabecalhoInterno>
        <ContainerInterno style={{height: 'calc(100vh - 230px)'}}>
          <Row>
            <Col className="text-left">

            </Col>
            <Col className="text-right">
              {
                selecionado
                  ? <MenuTabela selecionado={selecionado} remove={this.removerBancas}/>
                  : []
              }
            </Col>
          </Row>
          {
            bancas && bancas.length > 0
              ?
              <div className="tableContainer">
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="text-center" width="30px"></th>
                    <th className="text-center" width="30px">#</th>
                    <th>Banca</th>
                    <th>Site</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    bancas.map(banca =>
                      <tr key={banca.id}>
                        <td className="text-center">
                          <input value={banca.id} type="checkbox" size="sm" onChange={e => this.selecionarBancas(e)}/>
                        </td>
                        <td>{banca.id}</td>
                        <td><Link to={`/bancas/${banca.id}`}>{banca.nome}</Link></td>
                        <td><a target="_BLANK" rel="noopener noreferrer" href={banca.site}>{banca.site}</a></td>
                      </tr>
                    )
                  }
                </tbody>
                </Table>
                </div>
              :
              <Alert variant="warning">Não há bancas. <Link to={`/bancas/create`} className="text-primary">Clique aqui</Link> para cadastrar.</Alert>
          }

        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    bancas: state.bancas,
    login: state.login,
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getBancas, deleteBancas }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Bancas)