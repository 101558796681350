import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUsuarios, deleteUsuarios } from "../../actions/UsuariosAction";
import Loading from "../../components/Loading";
import CabecalhoInterno from "../../components/CabecalhoInterno";
import ContainerInterno from "../../components/ContainerInterno";
import { Table, Row, Col } from "react-bootstrap";
import MenuTabela from "../../components/MenuTabela";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

class Usuarios extends Component {
  state = {
    usuarios: [],
    login: [],
    usuariosSelecionados: [],
  };

  componentDidMount() {
    this.props.getUsuarios(this.props.login.accessToken);
  }

  selecionarUsuarios = (e) => {
    const id = e.target.value;
    const usuariosSelecionados = {
      ...this.state.usuariosSelecionados,
      [id]: e.target.checked,
    };
    this.setState({ usuariosSelecionados: usuariosSelecionados });
  };

  removerUsuarios = (e) => {
    const { usuariosSelecionados } = this.state;
    var usuarios = Object.keys(usuariosSelecionados).map((key) => {
      if (usuariosSelecionados[key]) {
        return key;
      }
      return false;
    });
    usuarios = usuarios.filter(Boolean);
    Swal.fire({
      title: `Deseja realmente remover o${
        usuarios.length !== 1 ? "s" : ""
      } usuário${usuarios.length !== 1 ? "s" : ""}?`,
      text: `${usuarios.length} selecionado${
        usuarios.length !== 1 ? "s" : ""
      }.`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Ok",
      confirmButtonColor: "#dc3545",
      focusCancel: true,
    }).then((e) => {
      if (e.value) {
        this.props.deleteUsuarios(
          this.props.login.accessToken,
          usuarios.join()
        );
      }
    });
  };

  render() {
    const { usuariosSelecionados } = this.state;
    const { usuarios, loadUsuarios } = this.props.usuarios;
    if (loadUsuarios || !usuarios) {
      return <Loading></Loading>;
    }
    var selecionado = Object.keys(usuariosSelecionados).find((key) => {
      return usuariosSelecionados[key];
    });
    return (
      <div>
        <CabecalhoInterno addUrl="/usuarios/create">
          <h3>Usuários</h3>
        </CabecalhoInterno>
        <ContainerInterno style={{ height: "calc(100vh - 230px)" }}>
          <Row>
            <Col className="text-left"></Col>
            <Col className="text-right">
              {selecionado ? (
                <MenuTabela
                  selecionado={selecionado}
                  remove={this.removerUsuarios}
                />
              ) : (
                []
              )}
            </Col>
          </Row>
          <div className="tableContainer">
            <Table striped hover>
              <thead>
                <tr>
                  <th className="text-center" width="30px"></th>
                  <th className="text-center" width="30px">
                    #
                  </th>
                  <th>Usuário</th>
                  <th>E-mail</th>
                  <th>Nome</th>
                  <th>Perfil</th>
                  <th>Nível</th>
                  <th>Status</th>
                  <th>Criação</th>
                </tr>
              </thead>
              <tbody>
                {usuarios
                  ? usuarios.map((usuario) => (
                      <tr key={usuario.id}>
                        <td className="text-center">
                          <input
                            value={usuario.id}
                            type="checkbox"
                            size="sm"
                            onChange={(e) => this.selecionarUsuarios(e)}
                          />
                        </td>
                        <td>{usuario.id}</td>
                        <td>
                          <Link to={`/usuarios/${usuario.id}`}>
                            {usuario.usuario}
                          </Link>
                        </td>
                        <td>{usuario.email}</td>
                        <td>{usuario.nome}</td>
                        <td>{usuario.perfil}</td>
                        <td>{usuario.nivel_de_acesso}</td>
                        <td>{usuario.status}</td>
                        <td>{usuario.created_at}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </div>
        </ContainerInterno>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usuarios: state.usuarios,
  login: state.login,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUsuarios, deleteUsuarios }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Usuarios);
