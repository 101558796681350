import React, { Component } from 'react'
import './style.css'
import { Container, Row, Col, Button} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

export default class CabecalhoInterno extends Component
{
  goBack() {
    this.props.history.goBack()
  }

  render() {
    return (
      <Container fluid className="py-2 px-0">
        <Row className="py-4" style={{ paddingLeft: 20, paddingRight: 20}}>
          <Col>{this.props.children}</Col>
          <Col sm="auto">
            {
              this.props.addUrl ?
                <Link to={this.props.addUrl}>
                  <Button size="sm" variant="primary" type="button" className="ml-3">
                    <FontAwesomeIcon size="sm" icon={faPlus} /> Adicionar
                </Button>
                </Link>
                : null
            }
            {
              this.props.editUrl ?
                <Link to={this.props.editUrl}>
                  <Button size="sm" variant="primary" type="button" className="ml-3">
                    <FontAwesomeIcon size="sm" icon={faEdit} /> Editar
                  </Button>
                </Link>
                : null
            }
            {
              this.props.history ?
                <Button onClick={e => this.goBack(this.props)} size="sm" variant="white" type="button" className="ml-3">
                  <FontAwesomeIcon size="sm" icon={faChevronLeft} /> Voltar
                </Button>
                : null
            }
          </Col>
        </Row>
      </Container>
    )
  }
}