import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { updateOrgao, getOrgao } from '../../actions/OrgaosAction'
import Loading from '../../components/Loading'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import { Form, Col, Row, Button, Alert } from 'react-bootstrap'
import { Redirect } from "react-router"

class OrgaosEdit extends Component {

  state = {
    formNome: '',
    formUF: '',
    erro: null,
    erroShow: false
  }

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getOrgao(id)
  }

  setError = (errorMessage = 'Erro ao tentar criar o órgão.') => {
    this.setState({
      erro: errorMessage,
      erroShow: true
    })
    document.getElementsByClassName('containerInterno')[0].scrollTop = 0
    return false
  }

  formSubmit = (e) => {
    e.preventDefault()
    const { formNome, formUF } = this.state
    const { orgao } = this.props.orgaos
    const { id } = this.props.match.params
    var errorMessage = ""
    if (!formNome && !orgao.nome) {
      errorMessage += "* Preencha o nome.<br />"
    }
    if (!formUF && !orgao.UF) {
      errorMessage += "* Preencha a UF.<br />"
    }
    if (errorMessage !== "") {
      return this.setError(errorMessage)
    }
    const body = {
      nome: formNome ? formNome : orgao.nome,
      UF: formUF ? formUF : orgao.UF
    }
    this.props.updateOrgao(this.props.login.accessToken, id, body)
  }

  render() {

    const { orgao, loadOrgaos } = this.props.orgaos
    const { redirectTo } = this.props.redirect
    const { formNome, formUF, erro, erroShow } = this.state

    if (loadOrgaos || !orgao) {
      return (
        <Loading></Loading>
      )
    }

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <div>
        <CabecalhoInterno history={this.props.history}>
          <h3>Editar Órgão</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Form onSubmit={this.formSubmit}>
            {erro && erroShow ? <Alert variant="danger" onClick={() => this.setState({erroShow: false})} ><div dangerouslySetInnerHTML={{ __html: erro }} /></Alert> : []}
            <Form.Group as={Row} controlId="formNome">
              <Form.Label column sm="1" className="text-right">
                Nome
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formNome ? formNome : orgao.nome}
                  type="text"
                  name="nome"
                  onChange={e => this.setState({ formNome: e.target.value, erroShow: false })}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formNome">
              <Form.Label column sm="1" className="text-right">
                UF
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formUF ? formUF : orgao.UF}
                  as="select"
                  type="text"
                  name="UF"
                  onChange={e => this.setState({ formUF: e.target.value, erroShow: false })}
                >
                  <option value="NACIONAL">Nível Nacional</option>
                  <option value="AC">AC</option>
                  <option value="AL">AL</option>
                  <option value="AM">AM</option>
                  <option value="AP">AP</option>
                  <option value="BA">BA</option>
                  <option value="CE">CE</option>
                  <option value="DF">DF</option>
                  <option value="ES">ES</option>
                  <option value="GO">GO</option>
                  <option value="MA">MA</option>
                  <option value="MG">MG</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="PA">PA</option>
                  <option value="PB">PB</option>
                  <option value="PE">PE</option>
                  <option value="PI">PI</option>
                  <option value="PR">PR</option>
                  <option value="RJ">RJ</option>
                  <option value="RN">RN</option>
                  <option value="RO">RO</option>
                  <option value="RR">RR</option>
                  <option value="RS">RS</option>
                  <option value="SC">SC</option>
                  <option value="SE">SE</option>
                  <option value="SP">SP</option>
                  <option value="TO">TO</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={{ span: 11, offset: 1 }}>
                <Button type="submit">Editar</Button>
              </Col>
            </Form.Group>
          </Form>
        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    orgaos: state.orgaos,
    login: state.login,
    redirect: state.redirect
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateOrgao, getOrgao }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OrgaosEdit)