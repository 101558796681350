import React, { Component } from 'react'
import './style.css'

import { Row } from 'react-bootstrap'
import imgLogoIcon from '../../images/logo-icon.jpg'

class Logo extends Component {
  render() {
    return (
      <Row className="logo p-2">
          <img
            alt=""
            src={imgLogoIcon}
            className="d-inline-block align-top"
          />{' '}
          <div className="cade-direito">Cadê Direito</div>
      </Row>
    )
  }
}

export default Logo

