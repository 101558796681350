import React, { Component } from "react";
import CabecalhoInterno from "../../components/CabecalhoInterno";
import ContainerInterno from "../../components/ContainerInterno";
import ErrosReportados from "../../components/ErrosReportados";
import SimuladosReportados from "../../components/SimuladosReportados";
//import GerarSiteMap from "../../components/GerarSiteMap";
import { Table, Button } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getComentarios,
  aprovarComentario,
  desaprovarComentario,
} from "../../actions/ComentariosAction";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";

class Principal extends Component {
  componentDidMount() {
    const { accessToken } = this.props.login;
    this.props.getComentarios(accessToken);
  }

  aprovarComentario = (id) => {
    this.props.aprovarComentario(this.props.login.accessToken, id);
    Swal.fire({
      title: "Comentário aprovado com sucesso!",
      type: "success",
      confirmButtonText: "Ok",
    });
    setTimeout(() => {
      this.props.getComentarios(this.props.login.accessToken);
    }, 1000);
  };

  desaprovarComentario = (id) => {
    this.props.desaprovarComentario(this.props.login.accessToken, id);
    Swal.fire({
      title: "Comentário desaprovado com sucesso!",
      type: "success",
      confirmButtonText: "Ok",
    });
    setTimeout(() => {
      this.props.getComentarios(this.props.login.accessToken);
    }, 1000);
  };

  render() {
    const { comentarios, loadComentarios } = this.props.comentarios;

    if (loadComentarios) {
      return <Loading></Loading>;
    }

    return (
      <div>
        <CabecalhoInterno>
          <h3>Principal</h3>
        </CabecalhoInterno>
        {/*<GerarSiteMap />*/}
        <ContainerInterno>
          <div>
            <h5>Comentários aguardando aprovação</h5>
            <Table striped hover>
              <thead>
                <tr>
                  <th style={{ width: 128, textAlign: "center" }}>Aprovar?</th>
                  <th>ID da questão</th>
                  <th>Usuário</th>
                  <th>Texto</th>
                  <th>Criado em</th>
                </tr>
              </thead>
              <tbody>
                {comentarios
                  ? comentarios.map((comentario) => {
                      return (
                        <tr key={comentario.id}>
                          <td>
                            <Button
                              size="sm"
                              variant="success"
                              onClick={() =>
                                this.aprovarComentario(comentario.id)
                              }
                            >
                              Sim
                            </Button>{" "}
                            <Button
                              size="sm"
                              variant="danger"
                              onClick={() =>
                                this.desaprovarComentario(comentario.id)
                              }
                            >
                              Não
                            </Button>
                          </td>
                          <td>
                            <a
                              href={`https://www.cadedireito.com.br/questoes/${comentario.questao.url}`}
                              target="__BLANK"
                            >
                              {comentario.questao.id}
                            </a>
                          </td>
                          <td>{comentario.usuario.nome}</td>
                          <td
                            dangerouslySetInnerHTML={{
                              __html: comentario.texto,
                            }}
                          >
                            {}
                          </td>
                          <td>{comentario.created_at}</td>
                        </tr>
                      );
                    })
                  : []}
              </tbody>
            </Table>
          </div>
        </ContainerInterno>
        {<ErrosReportados accessToken={this.props.login.accessToken} />}
        {<SimuladosReportados accessToken={this.props.login.accessToken} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
  comentarios: state.comentarios,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getComentarios, aprovarComentario, desaprovarComentario },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Principal);
