import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getConcursos, deleteConcursos } from "../../actions/ConcursosAction";
import Loading from "../../components/Loading";
import CabecalhoInterno from "../../components/CabecalhoInterno";
import ContainerInterno from "../../components/ContainerInterno";
import { Table, Alert, Row, Col } from "react-bootstrap";
import MenuTabela from "../../components/MenuTabela";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

class Concursos extends Component {
  state = {
    concursos: [],
    concursosSelecionados: [],
  };

  componentDidMount() {
    this.props.getConcursos();
  }

  selecionarConcursos = (e) => {
    const id = e.target.value;
    const concursosSelecionados = {
      ...this.state.concursosSelecionados,
      [id]: e.target.checked,
    };
    this.setState({ concursosSelecionados: concursosSelecionados });
  };

  removerConcursos = (e) => {
    const { concursosSelecionados } = this.state;
    var concursos = Object.keys(concursosSelecionados).map((key) => {
      if (concursosSelecionados[key]) {
        return key;
      }
      return false;
    });
    concursos = concursos.filter(Boolean);
    Swal.fire({
      title: `Deseja realmente remover o${
        concursos.length !== 1 ? "s" : ""
      } concurso${concursos.length !== 1 ? "s" : ""}?`,
      text: `${concursos.length} selecionado${
        concursos.length !== 1 ? "s" : ""
      }.`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Ok",
      confirmButtonColor: "#dc3545",
      focusCancel: true,
    }).then((e) => {
      if (e.value) {
        this.props.deleteConcursos(
          this.props.login.accessToken,
          concursos.join()
        );
      }
    });
  };

  render() {
    const { concursosSelecionados } = this.state;
    const { concursos, loadConcursos } = this.props.concursos;

    if (loadConcursos) {
      return <Loading></Loading>;
    }
    var selecionado = Object.keys(concursosSelecionados).find((key) => {
      return concursosSelecionados[key];
    });

    return (
      <div>
        <CabecalhoInterno addUrl="/concursos/create">
          <h3>Concursos</h3>
        </CabecalhoInterno>
        <ContainerInterno style={{ height: "calc(100vh - 230px)" }}>
          <Row>
            <Col className="text-left"></Col>
            <Col className="text-right">
              {selecionado ? (
                <MenuTabela
                  selecionado={selecionado}
                  remove={this.removerConcursos}
                />
              ) : (
                []
              )}
            </Col>
          </Row>
          {concursos && concursos.length ? (
            <div className="tableContainer">
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="text-center" width="30px"></th>
                    <th className="text-center" width="30px">
                      #
                    </th>
                    <th>Concurso</th>
                    <th>Cargo</th>
                    <th>Banca</th>
                    <th>Órgão</th>
                    <th>ano</th>
                  </tr>
                </thead>
                <tbody>
                  {concursos.map((concurso) => (
                    <tr key={concurso.id}>
                      <td className="text-center">
                        <input
                          value={concurso.id}
                          type="checkbox"
                          size="sm"
                          onChange={(e) => this.selecionarConcursos(e)}
                        />
                      </td>
                      <td>{concurso.id}</td>
                      <td>
                        <Link to={`/concursos/${concurso.id}`}>
                          {concurso.nome}
                        </Link>
                      </td>
                      <td>
                        {concurso.cargo ? (
                          <Link to={`/cargos/${concurso.cargo.id}`}>
                            {concurso.cargo.nome}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {concurso.banca ? (
                          <Link to={`/bancas/${concurso.banca.id}`}>
                            {concurso.banca.nome}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {concurso.orgao ? (
                          <Link to={`/orgaos/${concurso.orgao.id}`}>
                            {concurso.orgao.nome}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{concurso.ano}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <Alert variant="warning">
              Não há concursos.{" "}
              <Link to={`/concursos/create`} className="text-primary">
                Clique aqui
              </Link>{" "}
              para cadastrar.
            </Alert>
          )}
        </ContainerInterno>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  concursos: state.concursos,
  login: state.login,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getConcursos, deleteConcursos }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Concursos);
