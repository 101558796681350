import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loading from '../../components/Loading'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'

class Exportar extends Component {

  state = {
  }

  render() {
    if (1 === 2) {
      return (
        <Loading></Loading>
      )
    }

    return (
      <div>
        <CabecalhoInterno>
          <h3>Exportar</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <h1>Em construção...</h1>
        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    concursos: state.concursos,
    login: state.login,
  })

/*const mapDispatchToProps = dispatch =>
  bindActionCreators({  }, dispatch)*/

export default connect(mapStateToProps/*, mapDispatchToProps*/)(Exportar)