import React, { Component } from 'react'
import { Pagination } from 'react-bootstrap'
import './style.css'

export default class Paginate extends Component {

  createPages = () => {
    const { curPage, pages } = this.props

    var itens = [];
    const pagination = this.pagination(+curPage, +pages);
    pagination.map(p => {
      return itens.push(
        <Pagination.Item
          onClick={
            (e) => { this.props.toPage(e.target.text)}
          }
          active={ +curPage === p }
          key={p}>
          {p}
        </Pagination.Item>
      )
    })
    return itens
  }


  pagination(currentPage, nrOfPages) {
    var delta = 2,
        range = [],
        rangeWithDots = [],
        l;

    range.push(1);
    if (nrOfPages <= 1){
 	    return range;
    }
    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
        if (i < nrOfPages && i > 1) {
            range.push(i);
        }
    }
    range.push(nrOfPages);
    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }
    return rangeWithDots;
  }

  render() {
    const { curPage, count } = this.props

    const aPage = count < (curPage * 50) ? count : (curPage * 50)

    return (
      <div>
        <Pagination className="float-left">
          {
            this.createPages()
          }
        </Pagination>
        <label className="pagination-text">{1 + (curPage - 1) * 50} a {aPage} de {count}</label>
      </div>

    )
  }
}