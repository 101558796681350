import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getBanca, deleteBancas } from '../../actions/BancasAction'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import Loading from '../../components/Loading'
import { Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class BancasShow extends Component {

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getBanca(id)
  }

  render() {
    const { banca, loadBancas } = this.props.bancas
    const { id } = this.props.match.params

    if (loadBancas || !banca) {
      return (
        <Loading></Loading>
      )
    }

    return (
      <div>
        <CabecalhoInterno editUrl={`/bancas/${id}/edit`} >
          <h3>Banca</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Nome</div>
              <div className="value">{banca.nome}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col>
              <h5>Concursos</h5>
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="text-center" width="30px"></th>
                    <th className="text-center" width="30px">#</th>
                    <th>Concurso</th>
                    <th>ano</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    banca.concursos
                      ? banca.concursos.map(concurso =>
                        <tr key={concurso.id}>
                          <td className="text-center">
                            <input value={concurso.id} type="checkbox" size="sm" onChange={e => this.selecionarConcursos(e)}/>
                          </td>
                          <td>{concurso.id}</td>
                          <td><Link to={`/concursos/${concurso.id}`}>{concurso.nome}</Link></td>
                          <td>{concurso.ano}</td>
                        </tr>
                      )
                      : null
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = (state) =>
  ({
    bancas: state.bancas,
    login: state.login,
  })


const mapDispatchToProps = dispatch =>
  bindActionCreators({ getBanca, deleteBancas }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(BancasShow)