import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getMaterias, deleteMaterias } from "../../actions/MateriasAction";
import Loading from "../../components/Loading";
import CabecalhoInterno from "../../components/CabecalhoInterno";
import ContainerInterno from "../../components/ContainerInterno";
import {
  Table,
  Alert,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import MenuTabela from "../../components/MenuTabela";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

class Materias extends Component {
  state = {
    materias: [],
    materiasSelecionadas: [],
    procurar: null,
  };

  componentDidMount() {
    this.props.getMaterias();
  }

  selecionarMaterias = (e) => {
    const id = e.target.value;
    const materiasSelecionadas = {
      ...this.state.materiasSelecionadas,
      [id]: e.target.checked,
    };
    this.setState({ materiasSelecionadas: materiasSelecionadas });
  };

  removerMaterias = (e) => {
    const { materiasSelecionadas } = this.state;
    var materias = Object.keys(materiasSelecionadas).map((key) => {
      if (materiasSelecionadas[key]) {
        return key;
      }
      return false;
    });
    materias = materias.filter(Boolean);
    Swal.fire({
      title: `Deseja realmente remover a${
        materias.length !== 1 ? "s" : ""
      } matéria${materias.length !== 1 ? "s" : ""}?`,
      text: `${materias.length} selecionada${
        materias.length !== 1 ? "s" : ""
      }.`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Ok",
      confirmButtonColor: "#dc3545",
      focusCancel: true,
    }).then((e) => {
      if (e.value) {
        this.props.deleteMaterias(
          this.props.login.accessToken,
          materias.join()
        );
      }
    });
  };

  onSearch = (e) => {
    e.preventDefault();
    const { procurar } = this.state;
    this.props.getMaterias({ materia: procurar });
  };

  render() {
    const { materiasSelecionadas } = this.state;
    const { materias, loadMaterias } = this.props.materias;

    if (loadMaterias) {
      return <Loading></Loading>;
    }

    var selecionado = Object.keys(materiasSelecionadas).find((key) => {
      return materiasSelecionadas[key];
    });

    return (
      <div>
        <CabecalhoInterno addUrl="/materias/create">
          <h3>Matérias</h3>
        </CabecalhoInterno>
        <ContainerInterno style={{ height: "calc(100vh - 230px)" }}>
          <Row>
            <Col className="text-left"></Col>
            <Col className="text-right">
              {selecionado ? (
                <MenuTabela
                  selecionado={selecionado}
                  remove={this.removerMaterias}
                />
              ) : (
                []
              )}
            </Col>
          </Row>

          <Row className="my-2">
            <Col md={"auto"}>
              <Form onSubmit={this.onSearch}>
                <InputGroup className="mb-2" style={{ width: 400 }}>
                  <FormControl
                    name="materia"
                    placeholder="Procurar"
                    value={this.state.procurar ? this.state.procurar : ""}
                    onChange={(e) =>
                      this.setState({ procurar: e.target.value })
                    }
                  />
                  <InputGroup.Append>
                    <Button type={"submit"}>
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </Col>
          </Row>
          {materias && materias.length > 0 ? (
            <div className="tableContainer">
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="text-center" width="30px"></th>
                    <th className="text-center" width="30px">
                      #
                    </th>
                    <th>Materia</th>
                  </tr>
                </thead>
                <tbody>
                  {materias.map((materia) => (
                    <tr key={materia.id}>
                      <td className="text-center">
                        <input
                          value={materia.id}
                          type="checkbox"
                          size="sm"
                          onChange={(e) => this.selecionarMaterias(e)}
                        />
                      </td>
                      <td className="text-center">{materia.id}</td>
                      <td>
                        <Link to={`/materias/${materia.id}`}>
                          {materia.nome}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <Alert variant="warning">
              Não há matérias.{" "}
              <Link to={`/materias/create`} className="text-primary">
                Clique aqui
              </Link>{" "}
              para cadastrar.
            </Alert>
          )}
        </ContainerInterno>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  materias: state.materias,
  login: state.login,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getMaterias, deleteMaterias }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Materias);
