import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getOrgao, deleteOrgaos } from '../../actions/OrgaosAction'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import Loading from '../../components/Loading'
import { Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class OrgaosShow extends Component {

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getOrgao(id)
  }

  render() {
    const { orgao, loadOrgaos } = this.props.orgaos
    const { id } = this.props.match.params

    if (loadOrgaos || !orgao) {
      return (
        <Loading></Loading>
      )
    }

    return (
      <div>
        <CabecalhoInterno editUrl={`/orgaos/${id}/edit`} >
          <h3>Órgao</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Nome</div>
              <div className="value">{orgao.nome}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">UF</div>
              <div className="value">{orgao.UF}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col>
              <h5>Concursos</h5>
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="text-center" width="30px"></th>
                    <th className="text-center" width="30px">#</th>
                    <th>Concurso</th>
                    <th>ano</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    orgao.concursos
                      ? orgao.concursos.map(concurso =>
                        <tr key={concurso.id}>
                          <td className="text-center">
                            <input value={concurso.id} type="checkbox" size="sm" onChange={e => this.selecionarConcursos(e)}/>
                          </td>
                          <td>{concurso.id}</td>
                          <td><Link to={`/concursos/${concurso.id}`}>{concurso.nome}</Link></td>
                          <td>{concurso.ano}</td>
                        </tr>
                      )
                      : null
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = (state) =>
  ({
    orgaos: state.orgaos,
    login: state.login,
  })


const mapDispatchToProps = dispatch =>
  bindActionCreators({ getOrgao, deleteOrgaos }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OrgaosShow)