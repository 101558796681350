import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUsuario, deleteUsuarios } from "../../actions/UsuariosAction";
import CabecalhoInterno from "../../components/CabecalhoInterno";
import ContainerInterno from "../../components/ContainerInterno";
import Loading from "../../components/Loading";
import { Row, Col } from "react-bootstrap";

class UsuariosShow extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getUsuario(this.props.login.accessToken, id);
  }

  render() {
    const { usuario, loadUsuarios } = this.props.usuarios;
    const { id } = this.props.match.params;
    if (loadUsuarios || !usuario) {
      return <Loading></Loading>;
    }
    return (
      <div>
        <CabecalhoInterno
          history={this.props.history}
          editUrl={`/usuarios/${id}/edit`}
        >
          <h3>Usuario</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Usuário</div>
              <div className="value">{usuario.usuario}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">E-mail</div>
              <div className="value">{usuario.email}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Nome</div>
              <div className="value">{usuario.nome}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Perfil</div>
              <div className="value">{usuario.perfil}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Nível</div>
              <div className="value">{usuario.nivel_de_acesso}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Status</div>
              <div className="value">{usuario.status}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Data de criação</div>
              <div className="value">
                {usuario.created_at ? usuario.created_at : "-"}
              </div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Última alteração</div>
              <div className="value">{usuario.updated_at}</div>
            </Col>
          </Row>
        </ContainerInterno>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usuarios: state.usuarios,
  login: state.login,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUsuario, deleteUsuarios }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsuariosShow);
