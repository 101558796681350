import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faDatabase, faChartLine, faSearch, faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import { faGitlab } from '@fortawesome/free-brands-svg-icons'
import './style.css'

class Configuracoes extends Component {
  render() {
    return (
      <div className="configuracoes">

        <div className="configuracoesBox">
          <h6>Links</h6>
          <ul>
            <li>
              <a href="http://amysql.k8.com.br/" target="_BLANK" rel="noopener noreferrer">
                <FontAwesomeIcon size="sm" icon={faDatabase} className="configuracoesIcone"/> Banco de dados
              </a>
            </li>
            <li>
              <a href="https://webmail7.ultramail.com.br/" target="_BLANK" rel="noopener noreferrer">
                <FontAwesomeIcon size="sm" icon={faEnvelope} className="configuracoesIcone" /> E-mail
              </a>
            </li>
            <li>
              <a href="https://analytics.google.com/analytics/web/?authuser=1#/report-home/a169214910w239105601p223403052" target="_BLANK" rel="noopener noreferrer">
                <FontAwesomeIcon size="sm" icon={faChartLine} className="configuracoesIcone" /> Analytics
              </a>
            </li>
            <li>
              <a href="https://search.google.com/u/1/search-console?resource_id=https%3A%2F%2Fwww.cadedireito.com.br%2F" target="_BLANK" rel="noopener noreferrer">
                <FontAwesomeIcon size="sm" icon={faSearch} className="configuracoesIcone" /> Search Console
              </a>
            </li>
            <li>
              <a href="https://developers.google.com/speed/pagespeed/insights/?url=https%3A%2F%2Fwww.cadedireito.com.br&hl=pt_BR&authuser=1" target="_BLANK" rel="noopener noreferrer">
                <FontAwesomeIcon size="sm" icon={faTachometerAlt} className="configuracoesIcone" /> PageSpeed Insights
              </a>
            </li>
          </ul>
          <h6>Git</h6>
          <ul>
            <li>
              <a href="https://gitlab.com/WeltonAzevedo/cadedireito-admin" target="_BLANK" rel="noopener noreferrer">
                <FontAwesomeIcon size="sm" icon={faGitlab} className="configuracoesIcone"/> Admin
              </a>
            </li>
            <li>
              <a href="https://gitlab.com/WeltonAzevedo/cadedireito-api" target="_BLANK" rel="noopener noreferrer">
                <FontAwesomeIcon size="sm" icon={faGitlab} className="configuracoesIcone"/> Api
              </a>
            </li>
            <li>
              <a href="https://gitlab.com/WeltonAzevedo/cadedireito-site" target="_BLANK" rel="noopener noreferrer">
                <FontAwesomeIcon size="sm" icon={faGitlab} className="configuracoesIcone"/> Site
              </a>
            </li>
            <li>
              <a href="https://gitlab.com/WeltonAzevedo/cadedireito-support" target="_BLANK" rel="noopener noreferrer">
                <FontAwesomeIcon size="sm" icon={faGitlab} className="configuracoesIcone"/> Support
              </a>
            </li>
          </ul>
        </div>

      </div>
    )
  }
}

export default Configuracoes