const INITIAL_STATE = {
  estado: 'CONTRAIDO'
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURACOES_CONTRAIR': {
      return {
        ...state,
        estado: 'CONTRAIDO'
      }
    }
    case 'CONFIGURACOES_EXPANDIR': {
      return {
        ...state,
        estado: 'EXPANDIDO'
      }
    }
    default: {
      return state
    }
  }
}