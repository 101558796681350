import React, { useState, useEffect } from "react";
import ContainerInterno from "../../components/ContainerInterno";
import MenuTabela from "../../components/MenuTabela";
import { Table, Row, Col } from "react-bootstrap";
import { API_URL } from "../../configs/config";
import Swal from "sweetalert2";

export default function ErrosReportados({ accessToken }) {
  const [errosReportados, setErrosReportados] = useState([]);
  const [errosSelecionados, setErrosSelecionados] = useState([]);

  useEffect(() => {
    async function loadErrosReportados() {
      let headers = new Headers({
        Authorization: `Basic ${accessToken}`,
        "Content-Type": "application/json",
      });
      const reportadosResponse = await fetch(`${API_URL}/reportados`, {
        method: "get",
        headers,
      });
      const reportados = await reportadosResponse.json();
      setErrosReportados(reportados);
    }
    loadErrosReportados();
  }, [accessToken]);

  function selecionarErros(e) {
    const id = e.target.value;
    const es = { ...errosSelecionados, [id]: e.target.checked };
    setErrosSelecionados(es);
  }

  async function removerErros2(erros) {
    const ids = erros.join();
    let headers = new Headers({
      Authorization: `Basic ${accessToken}`,
      "Content-Type": "application/json",
    });
    const reportadosResponse = await fetch(`${API_URL}/reportados/${ids}`, {
      method: "delete",
      headers,
    });
    await reportadosResponse.json();
    var ers = errosReportados.filter((er) => !erros.includes(er.id + ""));
    setErrosReportados(ers);
    setErrosSelecionados([]);
    Swal.fire({
      title: "Erros Reportados(s) removido(s) com sucesso!",
      type: "success",
      confirmButtonText: "Ok",
    });
  }

  function removerErros() {
    var erros = Object.keys(errosSelecionados).map((key) => {
      if (errosSelecionados[key]) {
        return key;
      }
      return false;
    });
    erros = erros.filter(Boolean);
    Swal.fire({
      title: `Deseja realmente remover o${erros.length !== 1 ? "s" : ""} erro${
        erros.length !== 1 ? "s" : ""
      }?`,
      text: `${erros.length} selecionado${erros.length !== 1 ? "s" : ""}.`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Ok",
      confirmButtonColor: "#dc3545",
      focusCancel: true,
    }).then((e) => {
      if (e.value) {
        removerErros2(erros);
      }
    });
  }

  var selecionado = Object.keys(errosSelecionados).find((key) => {
    return errosSelecionados[key];
  });

  return (
    <ContainerInterno className="mt-4">
      <div>
        <h5>Erros reportados</h5>
        <Row>
          <Col className="text-left"></Col>
          <Col className="text-right">
            {selecionado ? (
              <MenuTabela selecionado={selecionado} remove={removerErros} />
            ) : (
              []
            )}
          </Col>
        </Row>
        <Table striped hover>
          <thead>
            <tr>
              <th className="text-center" width="30px"></th>
              <th>#</th>
              <th>ID da questão</th>
              <th>Usuário</th>
              <th>E-mail</th>
              <th>Texto</th>
              <th>Reportado em</th>
            </tr>
          </thead>
          <tbody>
            {errosReportados
              ? errosReportados.map((erroReportado) => (
                  <tr key={erroReportado.id}>
                    <td className="text-center">
                      <input
                        value={erroReportado.id}
                        type="checkbox"
                        size="sm"
                        onChange={(e) => selecionarErros(e)}
                      />
                    </td>
                    <td>{erroReportado.id}</td>
                    <td>
                      <a
                        href={`https://www.cadedireito.com.br/questoes/${erroReportado.questao.url}`}
                        target="__BLANK"
                      >
                        {erroReportado.questao.id}
                      </a>
                    </td>
                    <td>{erroReportado.usuario.usuario}</td>
                    <td>{erroReportado.usuario.email}</td>
                    <td>{erroReportado.mensagem}</td>
                    <td>{erroReportado.created_at}</td>
                  </tr>
                ))
              : []}
          </tbody>
        </Table>
      </div>
    </ContainerInterno>
  );
}
