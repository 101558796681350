const INITIAL_STATE = {
  usuario: null,
  usuarios: null,
  loadUsuarios: false,
  count: null,
  curPage: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /** GET */
    case 'GET_USUARIO_REQUEST':
    case 'GET_USUARIOS_REQUEST': {
      return { ...state, loadUsuarios: true }
    }
    case 'GET_USUARIO_SUCCESS': {
      return { ...state, loadUsuarios: false, usuario: action.payload }
    }
    case 'GET_USUARIOS_SUCCESS': {
      return { ...state, loadUsuarios: false, usuarios: action.payload.data, count: action.payload.count, curPage: action.payload.curPage }
    }
    case 'GET_USUARIO_FAILURE':
    case 'GET_USUARIOS_FAILURE': {
      return { ...INITIAL_STATE, loadUsuarios: false }
    }

    /** STORE */
    case 'STORE_USUARIO_REQUEST': {
      return { ...state, loadUsuarios: true }
    }
    case 'STORE_USUARIO_SUCCESS': {
      return { ...state, loadUsuarios: false, successMessageAlert: 'Usuário criado com sucesso.' }
    }
    case 'STORE_USUARIO_FAILURE': {
      return { ...state, loadUsuarios: false }
    }

    /** DEFAULT */
    default: { return state }
  }
}