import Swal from 'sweetalert2'
import { API_URL } from '../configs/config'

export const getConcurso = (id) => {
  return dispatch => {
    dispatch({ type: 'GET_CONCURSO_REQUEST' })
    fetch(`${API_URL}/concurso/${id}`)
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'GET_CONCURSO_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'GET_CONCURSO_FAILURE',
        payload: error
      }))
  }
}

export const getConcursos = () => {
  return dispatch => {
    dispatch({ type: 'GET_CONCURSOS_REQUEST' })
    fetch(`${API_URL}/concurso`)
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'GET_CONCURSOS_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'GET_CONCURSOS_FAILURE',
        payload: error
      }))
  }
}

export const storeConcurso = (accessToken, opts = {}) => {
  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })
  const body = JSON.stringify(opts)
  return dispatch => {
    dispatch({ type: 'STORE_CONCURSO_REQUEST' })
    fetch(`${API_URL}/concurso`, {
      method: 'post',
      headers,
      body,
    })
      .then(T => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error('erro')
        }
        return T.json()
      })
      .then(response => {
        Swal.fire({
          title: 'Concurso criado com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'STORE_CONCURSO_SUCCESS',
          payload: response
        })
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao tentar salvar o Concurso.',
          type: 'error',
          confirmButtonColor: '#dc3545',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'STORE_CONCURSO_FAILURE',
          payload: error
        })
      })
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/concursos'
        })
      })
  }
}

export const updateConcurso = (accessToken, id, opts = {}) => {
  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })
  const body = JSON.stringify(opts)
  return dispatch => {
    dispatch({ type: 'UPDATE_CONCURSO_REQUEST' })
    fetch(`${API_URL}/concurso/${id}`, {
      method: 'put',
      headers,
      body,
    })
      .then(T => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error('erro')
        }
        return T.json()
      })
      .then(response => {
        Swal.fire({
          title: 'Concurso editado com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'UPDATE_CONCURSO_SUCCESS',
          payload: response
        })
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao tentar editar o Concurso.',
          type: 'error',
          confirmButtonColor: '#dc3545',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'UPDATE_CONCURSO_FAILURE',
          payload: error
        })
      })
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/concursos'
        })
      })
  }
}

export const deleteConcursos = (accessToken, ids = null) => {
  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })
  return dispatch => {
    dispatch({ type: 'DELETE_CONCURSO_REQUEST' })
    fetch(`${API_URL}/concurso/${ids}`, {
      method: 'delete',
      headers
    })
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'DELETE_CONCURSO_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'DELETE_CONCURSO_FAILURE',
        payload: error
      }))
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/concursos'
        })
        Swal.fire({
          title: 'Concurso(s) removido(s) com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        }).then(e => {
          window.location.reload();
        })
      })
  }
}