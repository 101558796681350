import { combineReducers } from 'redux'

import AreaLateralReducer from './AreaLateralReducer'
import AssuntosReducer from './AssuntosReducer'
import BancasReducer from './BancasReducer'
import CargosReducer from './CargosReducer'
import OrgaosReducer from './OrgaosReducer'
import ConcursosReducer from './ConcursosReducer'
import ConfiguracoesReducer from './ConfiguracoesReducer'
import LoginReducer from './LoginReducer'
import MateriasReducer from './MateriasReducer'
import QuestoesReducer from './QuestoesReducer'
import UsuariosReducer from './UsuariosReducer'
import ImportarReducer from './ImportarReducer'
import ComentariosReducer from './ComentariosReducer'
import Redirect from './Redirect'

const reducers = combineReducers({
  areaLateral: AreaLateralReducer,
  configuracoes: ConfiguracoesReducer,
  login: LoginReducer,
  bancas: BancasReducer,
  cargos: CargosReducer,
  orgaos: OrgaosReducer,
  concursos: ConcursosReducer,
  materias: MateriasReducer,
  assuntos: AssuntosReducer,
  questoes: QuestoesReducer,
  usuarios: UsuariosReducer,
  importar: ImportarReducer,
  comentarios: ComentariosReducer,
  redirect: Redirect
})

export default reducers