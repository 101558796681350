import React, { Component } from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faUsers, faBook, faPencilAlt, faFileAlt, faChalkboardTeacher, faHome, faUpload, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

class MenuLateral extends Component {
  render() {
    return (
      <Nav defaultActiveKey="/home" className="flex-column menuLateral">

        <div className="navLink">
          <Link to="/principal">
            <div className="faContainer">
              <FontAwesomeIcon icon={faHome} />
            </div>
            <span>Principal</span>
          </Link>
        </div>

        <div className="navLink">
          <Link to="/questoes">
            <div className="faContainer">
              <FontAwesomeIcon icon={faPencilAlt} />
            </div>
            <span>Questões</span>
          </Link>
          <Link className="navLinkPlus" to="/questoes/create">
            <FontAwesomeIcon icon={faPlusCircle} />
          </Link>
        </div>

        <div className="navLink">
          <Link to="/bancas">
            <div className="faContainer">
              <FontAwesomeIcon icon={faChalkboardTeacher} />
            </div>
            <span>Bancas</span>
          </Link>
          <Link className="navLinkPlus" to="/bancas/create">
            <FontAwesomeIcon icon={faPlusCircle} />
          </Link>
        </div>

        <div className="navLink">
          <Link to="/cargos">
            <div className="faContainer">
              <FontAwesomeIcon icon={faChalkboardTeacher} />
            </div>
            <span>Cargos</span>
          </Link>
          <Link className="navLinkPlus" to="/cargos/create">
            <FontAwesomeIcon icon={faPlusCircle} />
          </Link>
        </div>

        <div className="navLink">
          <Link to="/orgaos">
            <div className="faContainer">
              <FontAwesomeIcon icon={faChalkboardTeacher} />
            </div>
            <span>Órgãos</span>
          </Link>
          <Link className="navLinkPlus" to="/orgaos/create">
            <FontAwesomeIcon icon={faPlusCircle} />
          </Link>
        </div>

        <div className="navLink">
          <Link to="/concursos">
            <div className="faContainer">
              <FontAwesomeIcon icon={faFileAlt} />
            </div>
            <span>Concursos</span>
          </Link>
          <Link className="navLinkPlus" to="/concursos/create">
            <FontAwesomeIcon icon={faPlusCircle} />
          </Link>
        </div>

        <div className="navLink">
          <Link to="/materias">
            <div className="faContainer">
              <FontAwesomeIcon icon={faBook} />
            </div>
            <span>Matérias</span>
          </Link>
          <Link className="navLinkPlus" to="/materias/create">
            <FontAwesomeIcon icon={faPlusCircle} />
          </Link>
        </div>

        <div className="navLink">
          <Link to="/assuntos">
            <div className="faContainer">
              <FontAwesomeIcon icon={faCoffee} />
            </div>
            <span>Assuntos</span>
          </Link>
          <Link className="navLinkPlus" to="/assuntos/create">
            <FontAwesomeIcon icon={faPlusCircle} />
          </Link>
        </div>

        <div className="navLink">
          <Link to="/usuarios">
            <div className="faContainer">
              <FontAwesomeIcon icon={faUsers} />
            </div>
            <span>Usuários</span>
          </Link>
          <Link className="navLinkPlus" to="/usuarios/create">
            <FontAwesomeIcon icon={faPlusCircle} />
          </Link>
        </div>

        <div className="navLink">
          <Link to="/importar">
            <div className="faContainer">
              <FontAwesomeIcon icon={faUpload} />
            </div>
            <span>Importar</span>
          </Link>
        </div>

        <div className="navLink">
          <Link to="/cadastrorapido">
            <div className="faContainer">
              <FontAwesomeIcon icon={faUpload} />
            </div>
            <span>Cadastro rápido</span>
          </Link>
        </div>

        {/*<div className="navLink">
          <Link to="/exportar">
            <div className="faContainer">
              <FontAwesomeIcon icon={faDownload} />
            </div>
            <span>Exportar</span>
          </Link>
        </div>*/}

      </Nav>
    )
  }
}

export default MenuLateral