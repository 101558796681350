import Swal from 'sweetalert2'
import { API_URL } from '../configs/config'

export const getUsuario = (accessToken, id) => {
  const headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  })
  return dispatch => {
    dispatch({ type: 'GET_USUARIO_REQUEST' })
    fetch(`${API_URL}/usuario/${id}`, {
      method: 'get',
      headers
    })
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'GET_USUARIO_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'GET_USUARIO_FAILURE',
        payload: error
      }))
  }
}

export const getUsuarios = (accessToken) => {
  const headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  })
  return dispatch => {
    dispatch({ type: 'GET_USUARIOS_REQUEST' })
    fetch(`${API_URL}/usuario`, {
      method: 'get',
      headers
    })
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'GET_USUARIOS_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'GET_USUARIOS_FAILURE',
        payload: error
      }))
  }
}


export const storeUsuario = (accessToken, opts = {}) => {
  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })
  const body = JSON.stringify(opts)
  return dispatch => {
    dispatch({ type: 'STORE_USUARIO_REQUEST' })
    fetch(`${API_URL}/usuario`, {
      method: 'post',
      headers,
      body,
    })
      .then(T => {
        return T.json()
      })
      .then(response => {
        Swal.fire({
          title: 'Usuário criado com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'STORE_USUARIO_SUCCESS',
          payload: response
        })
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao tentar salvar o Usuário.',
          type: 'error',
          confirmButtonColor: '#dc3545',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'STORE_USUARIO_FAILURE',
          payload: error
        })
      })
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/usuarios'
        })
      })
  }
}

export const updateUsuario = (accessToken, id, opts = {}) => {
  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })
  const body = JSON.stringify(opts)
  return dispatch => {
    dispatch({ type: 'UPDATE_USUARIO_REQUEST' })
    fetch(`${API_URL}/usuario/${id}`, {
      method: 'put',
      headers,
      body,
    })
      .then(T => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error('erro')
        }
        return T.json()
      })
      .then(response => {
        Swal.fire({
          title: 'Usuário editado com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'UPDATE_USUARIO_SUCCESS',
          payload: response
        })
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao tentar editar o Usuário.',
          type: 'error',
          confirmButtonColor: '#dc3545',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'UPDATE_USUARIO_FAILURE',
          payload: error
        })
      })
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/usuarios'
        })
      })
  }
}

export const deleteUsuarios = (accessToken, ids = null) => {
  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })
  return dispatch => {
    dispatch({ type: 'DELETE_USUARIO_REQUEST' })
    fetch(`${API_URL}/usuario/${ids}`, {
      method: 'delete',
      headers
    })
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'DELETE_USUARIO_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'DELETE_USUARIO_FAILURE',
        payload: error
      }))
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/usuarios'
        })
        Swal.fire({
          title: 'Usuário(s) removido(s) com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        }).then(e => {
          window.location.reload();
        })
      })
  }
}