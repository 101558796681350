import Swal from "sweetalert2";
import { API_URL } from "../configs/config";

export const getQuestao = (id) => {
  return (dispatch) => {
    dispatch({ type: "GET_QUESTAO_REQUEST" });
    fetch(`${API_URL}/questao/${id}`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_QUESTAO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_QUESTAO_FAILURE",
          payload: error,
        })
      );
  };
};

export const getQuestoes = (data = null) => {
  var params = "";

  if (data && data["page"]) {
    params = `?page=${data["page"]}`;
  } else {
    params = `?page=1`;
  }

  if (data && data["enunciado"]) {
    params += `&enunciado=${data["enunciado"]}`;
  }

  if (data && data["cdid"]) {
    params += `&cdid=${data["cdid"]}`;
  }

  return (dispatch) => {
    dispatch({ type: "GET_QUESTOES_REQUEST" });
    fetch(`${API_URL}/questao${params}`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_QUESTOES_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_QUESTOES_FAILURE",
          payload: error,
        })
      );
  };
};

export const storeQuestao = (accessToken, opts = {}) => {
  let headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/json",
  });
  let data = {
    ...opts,
    //tipo: "múltipla escolha 5"
  };
  const body = JSON.stringify(data);
  return (dispatch) => {
    dispatch({ type: "STORE_QUESTAO_REQUEST" });
    fetch(`${API_URL}/questao`, {
      method: "post",
      headers,
      body,
    })
      .then((T) => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error("erro");
        }
        return T.json();
      })
      .then((response) => {
        Swal.fire({
          title: "Questão criada com sucesso!",
          type: "success",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "STORE_QUESTAO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro ao tentar salvar a Questão.",
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "STORE_QUESTAO_FAILURE",
          payload: error,
        });
      })
      .then(() => {
        dispatch({
          type: "REDIRECT",
          payload: "/questoes",
        });
      });
  };
};

export const updateQuestao = (accessToken, id, opts = {}) => {
  let headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/json",
  });
  let data = {
    ...opts,
  };
  const body = JSON.stringify(data);
  return (dispatch) => {
    dispatch({ type: "UPDATE_QUESTAO_REQUEST" });
    fetch(`${API_URL}/questao/${id}`, {
      method: "put",
      headers,
      body,
    })
      .then((T) => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error("erro");
        }
        return T.json();
      })
      .then((response) => {
        Swal.fire({
          title: "Questão editada com sucesso!",
          type: "success",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "UPDATE_QUESTAO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro ao tentar editar Questão.",
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "UPDATE_QUESTAO_FAILURE",
          payload: error,
        });
      })
      .then(() => {
        dispatch({
          type: "REDIRECT",
          payload: "/questoes",
        });
      });
  };
};

export const deleteQuestoes = (accessToken, ids = null) => {
  let headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/json",
  });
  return (dispatch) => {
    dispatch({ type: "DELETE_QUESTAO_REQUEST" });
    fetch(`${API_URL}/questao/${ids}`, {
      method: "delete",
      headers,
    })
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "DELETE_QUESTAO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "DELETE_QUESTAO_FAILURE",
          payload: error,
        })
      )
      .then(() => {
        dispatch({
          type: "REDIRECT",
          payload: "/questoes",
        });
        Swal.fire({
          title: "Questão(s) removida(s) com sucesso!",
          type: "success",
          confirmButtonText: "Ok",
        }).then((e) => {
          window.location.reload();
        });
      });
  };
};

export const atualizarCDID = () => {
  return (dispatch) => {
    dispatch({ type: "CDID_REQUEST" });
    fetch(`${API_URL}/cdid`, {
      method: "post",
    })
      .then((T) => {
        /*if (T.status !== 200 && T.status !== 201) {
          throw new Error("erro");
        }*/
        return T.json();
      })
      .then((response) => {
        Swal.fire({
          title: "CDIDs atualizados com sucesso!",
          type: "success",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "CDID_SUCCESS",
          payload: response,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro",
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "CDID_FAILURE",
          payload: error,
        });
      })
      .then(() => {
        dispatch({
          type: "REDIRECT",
          payload: "/questoes",
        });
      });
  };
};
