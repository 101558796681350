import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getAssunto, deleteAssuntos } from '../../actions/AssuntosAction'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import Loading from '../../components/Loading'
import { Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class AssuntosShow extends Component {

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getAssunto(id)
  }

  render() {
    const { assunto, loadAssuntos } = this.props.assuntos
    const { id } = this.props.match.params
    if (loadAssuntos || !assunto) {
      return (
        <Loading></Loading>
      )
    }
    return (
      <div>
        <CabecalhoInterno editUrl={`/assuntos/${id}/edit`} >
          <h3>Assunto</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Assunto</div>
              <div className="value">{assunto.nome}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Matéria</div>
              <div className="value"><Link to={`/materias/${assunto.materia.id}`}>{assunto.materia.nome}</Link>{}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Questões</div>
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="text-center" width="30px"></th>
                    <th className="text-center" width="30px">#</th>
                    <th>Enunciado</th>
                    <th>Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    assunto.questoes
                      ? assunto.questoes.map(questao =>
                        <tr key={questao.id}>
                          <td className="text-center">
                            <input value={assunto.id} type="checkbox" size="sm"/>
                          </td>
                          <td>{questao.id}</td>
                          <td><Link to={`/questoes/${questao.id}`}>{questao.enunciado}</Link>{}</td>
                          <td>{questao.tipo}</td>
                        </tr>
                      )
                      : null
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = (state) =>
  ({
    assuntos: state.assuntos,
    login: state.login,
  })


const mapDispatchToProps = dispatch =>
  bindActionCreators({ getAssunto, deleteAssuntos }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AssuntosShow)