import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getCargos, deleteCargos } from '../../actions/CargosAction'
import Loading from '../../components/Loading'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import { Table, Alert, Row, Col } from 'react-bootstrap'
import MenuTabela from '../../components/MenuTabela'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'

class Cargos extends Component {

  state = {
    cargos: [],
    cargosSelecionadas: []
  }

  componentDidMount() {
    this.props.getCargos()
  }

  selecionarCargos = e => {
    const id = e.target.value
    const cargosSelecionadas = { ...this.state.cargosSelecionadas, [id]: e.target.checked }
    this.setState({'cargosSelecionadas': cargosSelecionadas})
  }

  removerCargos = e => {
    const { cargosSelecionadas } = this.state
    var cargos = Object.keys(cargosSelecionadas).map((key) => {
      if (cargosSelecionadas[key]) {
        return key
      }
      return false
    })
    cargos = cargos.filter(Boolean)
    Swal.fire({
      title: `Deseja realmente remover a${cargos.length !== 1 ? 's' : ''} cargo${cargos.length !== 1 ? 's' : ''}?`,
      text: `${cargos.length} selecionada${cargos.length  !== 1 ? 's':''}.`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#dc3545',
      focusCancel: true,
    }).then(e => {
      if (e.value) {
        this.props.deleteCargos(this.props.login.accessToken, cargos.join())
      }
    })
  }

  render() {
    const { cargosSelecionadas } = this.state
    const { cargos, loadCargos } = this.props.cargos

    if (loadCargos) {
      return (
        <Loading></Loading>
      )
    }
    var selecionado = Object.keys(cargosSelecionadas).find((key) => {
      return cargosSelecionadas[key]
    })
    return (
      <div>
        <CabecalhoInterno addUrl="/cargos/create" >
          <h3>Cargos</h3>
        </CabecalhoInterno>
        <ContainerInterno style={{height: 'calc(100vh - 230px)'}}>
          <Row>
            <Col className="text-left">

            </Col>
            <Col className="text-right">
              {
                selecionado
                  ? <MenuTabela selecionado={selecionado} remove={this.removerCargos}/>
                  : []
              }
            </Col>
          </Row>
          {
            cargos && cargos.length > 0
              ?
              <div className="tableContainer">
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="text-center" width="30px"></th>
                    <th className="text-center" width="30px">#</th>
                    <th>Cargo</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    cargos.map(cargo =>
                      <tr key={cargo.id}>
                        <td className="text-center">
                          <input value={cargo.id} type="checkbox" size="sm" onChange={e => this.selecionarCargos(e)}/>
                        </td>
                        <td>{cargo.id}</td>
                        <td><Link to={`/cargos/${cargo.id}`}>{cargo.nome}</Link></td>
                      </tr>
                    )
                  }
                </tbody>
                </Table>
                </div>
              :
              <Alert variant="warning">Não há cargos. <Link to={`/cargos/create`} className="text-primary">Clique aqui</Link> para cadastrar.</Alert>
          }

        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    cargos: state.cargos,
    login: state.login,
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getCargos, deleteCargos }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Cargos)