import React, { useEffect, useState } from "react";
import ContainerInterno from "../../components/ContainerInterno";
import { Table, Row, Col } from "react-bootstrap";
import MenuTabela from "../../components/MenuTabela";
import { API_URL } from "../../configs/config";
import Swal from "sweetalert2";

function SimuladosReportados({ accessToken }) {
  const [selecionados, setSelecionados] = useState([]);
  console.log(selecionados);

  const [loading, setLoading] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);
  const [simuladosReportados, setSimuladosReportados] = useState([]);

  useEffect(() => {
    async function getSimuladosReportados() {
      setLoading(true);
      let headers = new Headers({
        Authorization: `Basic ${accessToken}`,
        "Content-Type": "application/json",
      });
      const _simuladosReportados = await fetch(
        `${API_URL}/simulados/denunciados`,
        {
          method: "get",
          headers,
        }
      );
      const simuladosReportados = await _simuladosReportados.json();
      console.log(simuladosReportados);

      setSimuladosReportados(simuladosReportados.data);
      setLoadComplete(true);
      setLoading(false);
    }
    getSimuladosReportados();
  }, [accessToken]);

  function removerReportados() {
    var reportados = Object.keys(selecionados).map((key) => {
      if (selecionados[key]) {
        return key;
      }
      return false;
    });
    reportados = reportados.filter(Boolean);
    Swal.fire({
      title: `Deseja realmente remover a${
        reportados.length !== 1 ? "s" : ""
      } denúncia${reportados.length !== 1 ? "s" : ""}?`,
      text: `${reportados.length} selecionada${
        reportados.length !== 1 ? "s" : ""
      }.`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Ok",
      confirmButtonColor: "#dc3545",
      focusCancel: true,
    }).then((e) => {
      if (e.value) {
        removerReportados2(reportados);
      }
    });
  }

  async function removerReportados2(reportados) {
    const ids = reportados.join();
    let headers = new Headers({
      Authorization: `Basic ${accessToken}`,
      "Content-Type": "application/json",
    });
    const _reportadosResponse = await fetch(
      `${API_URL}/simulados/denunciados/${ids}`,
      {
        method: "delete",
        headers,
      }
    );
    await _reportadosResponse.json();

    var sr = simuladosReportados.filter(
      (er) => !reportados.includes(er.id + "")
    );
    setSimuladosReportados(sr);
    setSelecionados([]);
    Swal.fire({
      title: "Denúncias removido(s) com sucesso!",
      type: "success",
      confirmButtonText: "Ok",
    });
  }

  function selecionarReportados(e) {
    const id = e.target.value;
    const es = { ...selecionados, [id]: e.target.checked };
    setSelecionados(es);
  }

  var selecionado = Object.keys(selecionados).find((key) => {
    return selecionados[key];
  });

  return (
    <ContainerInterno className="mt-4">
      <div>
        <h5>Simulados denunciados</h5>
        <Row>
          <Col className="text-left"></Col>
          <Col className="text-right">
            {selecionado ? (
              <MenuTabela
                selecionado={selecionado}
                remove={removerReportados}
              />
            ) : (
              []
            )}
          </Col>
        </Row>
        <Table striped hover>
          <thead>
            <tr>
              <th className="text-center" width="30px"></th>
              <th>#</th>
              <th>ID do simulado</th>
              <th>E-mail</th>
              <th>Texto</th>
              <th>Reportado em</th>
            </tr>
          </thead>
          <tbody>
            {loadComplete && !loading ? (
              <>
                {simuladosReportados.map((simuladoReportado) => (
                  <tr key={simuladoReportado.id}>
                    <td className="text-center">
                      <input
                        value={simuladoReportado.id}
                        type="checkbox"
                        size="sm"
                        onChange={(e) => selecionarReportados(e)}
                      />
                    </td>
                    <td>{simuladoReportado.id}</td>
                    <td>
                      <a
                        href={`https://www.cadedireito.com.br/simulado/???`}
                        target="__BLANK"
                      >
                        {simuladoReportado.idSimulado}
                      </a>
                    </td>
                    <td>{simuladoReportado.email}</td>
                    <td>{simuladoReportado.texto}</td>
                    <td>{simuladoReportado.created_at}</td>
                  </tr>
                ))}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>
    </ContainerInterno>
  );
}

export default SimuladosReportados;
