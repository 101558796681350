import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getOrgaos, deleteOrgaos } from '../../actions/OrgaosAction'
import Loading from '../../components/Loading'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import { Table, Alert, Row, Col } from 'react-bootstrap'
import MenuTabela from '../../components/MenuTabela'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'

class Orgaos extends Component {

  state = {
    orgaos: [],
    orgaosSelecionadas: []
  }

  componentDidMount() {
    this.props.getOrgaos()
  }

  selecionarOrgaos = e => {
    const id = e.target.value
    const orgaosSelecionadas = { ...this.state.orgaosSelecionadas, [id]: e.target.checked }
    this.setState({'orgaosSelecionadas': orgaosSelecionadas})
  }

  removerOrgaos = e => {
    const { orgaosSelecionadas } = this.state
    var orgaos = Object.keys(orgaosSelecionadas).map((key) => {
      if (orgaosSelecionadas[key]) {
        return key
      }
      return false
    })
    orgaos = orgaos.filter(Boolean)
    Swal.fire({
      title: `Deseja realmente remover a${orgaos.length !== 1 ? 's' : ''} orgao${orgaos.length !== 1 ? 's' : ''}?`,
      text: `${orgaos.length} selecionada${orgaos.length  !== 1 ? 's':''}.`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#dc3545',
      focusCancel: true,
    }).then(e => {
      if (e.value) {
        this.props.deleteOrgaos(this.props.login.accessToken, orgaos.join())
      }
    })
  }

  render() {
    const { orgaosSelecionadas } = this.state
    const { orgaos, loadOrgaos } = this.props.orgaos

    if (loadOrgaos) {
      return (
        <Loading></Loading>
      )
    }
    var selecionado = Object.keys(orgaosSelecionadas).find((key) => {
      return orgaosSelecionadas[key]
    })
    return (
      <div>
        <CabecalhoInterno addUrl="/orgaos/create" >
          <h3>Orgaos</h3>
        </CabecalhoInterno>
        <ContainerInterno style={{height: 'calc(100vh - 230px)'}}>
          <Row>
            <Col className="text-left">

            </Col>
            <Col className="text-right">
              {
                selecionado
                  ? <MenuTabela selecionado={selecionado} remove={this.removerOrgaos}/>
                  : []
              }
            </Col>
          </Row>
          {
            orgaos && orgaos.length > 0
              ?
              <div className="tableContainer">
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="text-center" width="30px"></th>
                    <th className="text-center" width="30px">#</th>
                    <th>UF</th>
                    <th>Órgão</th>
                    <th>Site</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    orgaos.map(orgao =>
                      <tr key={orgao.id}>
                        <td className="text-center">
                          <input value={orgao.id} type="checkbox" size="sm" onChange={e => this.selecionarOrgaos(e)}/>
                        </td>
                        <td>{orgao.id}</td>
                        <td>{orgao.UF}</td>
                        <td><Link to={`/orgaos/${orgao.id}`}>{orgao.nome}</Link></td>
                        <td><a target="_BLANK" rel="noopener noreferrer" href={orgao.site}>{orgao.site}</a></td>
                      </tr>
                    )
                  }
                </tbody>
                </Table>
                </div>
              :
              <Alert variant="warning">Não há orgaos. <Link to={`/orgaos/create`} className="text-primary">Clique aqui</Link> para cadastrar.</Alert>
          }

        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    orgaos: state.orgaos,
    login: state.login,
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getOrgaos, deleteOrgaos }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Orgaos)