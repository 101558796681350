import Swal from 'sweetalert2'
import { API_URL } from '../configs/config'

export const getAssunto = (id) => {
  return dispatch => {
    dispatch({ type: 'GET_ASSUNTO_REQUEST' })
    fetch(`${API_URL}/assunto/${id}`)
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'GET_ASSUNTO_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'GET_ASSUNTO_FAILURE',
        payload: error
      }))
  }
}

export const getAssuntos = () => {
  return dispatch => {
    dispatch({ type: 'GET_ASSUNTOS_REQUEST' })
    fetch(`${API_URL}/assunto`)
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'GET_ASSUNTOS_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'GET_ASSUNTOS_FAILURE',
        payload: error
      }))
  }
}

export const storeAssunto = (accessToken, opts = {}) => {
  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })
  const body = JSON.stringify(opts)
  return dispatch => {
    dispatch({ type: 'STORE_ASSUNTO_REQUEST' })
    fetch(`${API_URL}/assunto`, {
      method: 'post',
      headers,
      body,
    })
      .then(T => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error('erro')
        }
        return T.json()
      })
      .then(response => {
        Swal.fire({
          title: 'Assunto criado com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'STORE_ASSUNTO_SUCCESS',
          payload: response
        })
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao tentar salvar o Assunto.',
          type: 'error',
          confirmButtonColor: '#dc3545',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'STORE_ASSUNTO_FAILURE',
          payload: error
        })
      })
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/assuntos'
        })
      })
  }
}

export const updateAssunto = (accessToken, id, opts = {}) => {
  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })
  const body = JSON.stringify(opts)
  return dispatch => {
    dispatch({ type: 'UPDATE_ASSUNTO_REQUEST' })
    fetch(`${API_URL}/assunto/${id}`, {
      method: 'put',
      headers,
      body,
    })
      .then(T => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error('erro')
        }
        return T.json()
      })
      .then(response => {
        Swal.fire({
          title: 'Assunto editado com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'UPDATE_ASSUNTO_SUCCESS',
          payload: response
        })
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao tentar editar o Assunto.',
          type: 'error',
          confirmButtonColor: '#dc3545',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'UPDATE_ASSUNTO_FAILURE',
          payload: error
        })
      })
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/assuntos'
        })
      })
  }
}

export const deleteAssuntos = (accessToken, ids = null) => {
  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })
  return dispatch => {
    dispatch({ type: 'DELETE_ASSUNTO_REQUEST' })
    fetch(`${API_URL}/assunto/${ids}`, {
      method: 'delete',
      headers
    })
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'DELETE_ASSUNTO_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'DELETE_ASSUNTO_FAILURE',
        payload: error
      }))
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/assuntos'
        })
        Swal.fire({
          title: 'Assunto(s) removido(s) com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        }).then(e => {
          window.location.reload();
        })
      })
  }
}