import Swal from "sweetalert2";
import { API_URL } from "../configs/config";

/**
 * GET MATERIA
 * @param {*} id
 */
export const getMateria = (id) => {
  return (dispatch) => {
    dispatch({ type: "GET_MATERIA_REQUEST" });
    fetch(`${API_URL}/materia/${id}`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_MATERIA_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_MATERIA_FAILURE",
          payload: error,
        })
      );
  };
};

/**
 * GET MATERIAS
 */
export const getMaterias = (data = null) => {
  var params = "";
  if (data && data["materia"]) {
    params = `?materia=${data["materia"]}`;
  }
  return (dispatch) => {
    dispatch({ type: "GET_MATERIAS_REQUEST" });
    fetch(`${API_URL}/materia${params}`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_MATERIAS_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_MATERIAS_FAILURE",
          payload: error,
        })
      );
  };
};

/**
 * STORE MATERIA
 * @param {*} accessToken
 * @param {*} opts
 */
export const storeMateria = (accessToken, opts = {}) => {
  let headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/json",
  });
  const body = JSON.stringify(opts);
  return (dispatch) => {
    dispatch({ type: "STORE_MATERIA_REQUEST" });
    fetch(`${API_URL}/materia`, {
      method: "post",
      headers,
      body,
    })
      .then((T) => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error("erro");
        }
        return T.json();
      })
      .then((response) => {
        Swal.fire({
          title: "Matéria criada com sucesso!",
          type: "success",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "STORE_MATERIA_SUCCESS",
          payload: response,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro ao tentar salvar a Matéria.",
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "STORE_MATERIA_FAILURE",
          payload: error,
        });
      })
      .then(() => {
        dispatch({
          type: "REDIRECT",
          payload: "/materias",
        });
      });
  };
};

/**
 * UPDATE MATERIA
 * @param {*} accessToken
 * @param {*} id
 * @param {*} opts
 */
export const updateMateria = (accessToken, id, opts = {}) => {
  let headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/json",
  });
  const body = JSON.stringify(opts);
  return (dispatch) => {
    dispatch({ type: "UPDATE_MATERIA_REQUEST" });
    fetch(`${API_URL}/materia/${id}`, {
      method: "put",
      headers,
      body,
    })
      .then((T) => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error("erro");
        }
        return T.json();
      })
      .then((response) => {
        Swal.fire({
          title: "Matéria editada com sucesso!",
          type: "success",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "UPDATE_MATERIA_SUCCESS",
          payload: response,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro ao tentar editar Matéria.",
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "UPDATE_MATERIA_FAILURE",
          payload: error,
        });
      })
      .then(() => {
        dispatch({
          type: "REDIRECT",
          payload: "/materias",
        });
      });
  };
};

/**
 * DELETE MATERIAS
 * @param {*} accessToken
 * @param {*} ids
 */
export const deleteMaterias = (accessToken, ids = null) => {
  let headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/json",
  });
  return (dispatch) => {
    dispatch({ type: "DELETE_MATERIA_REQUEST" });
    fetch(`${API_URL}/materia/${ids}`, {
      method: "delete",
      headers,
    })
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "DELETE_MATERIA_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "DELETE_MATERIA_FAILURE",
          payload: error,
        })
      )
      .then(() => {
        dispatch({
          type: "REDIRECT",
          payload: "/materias",
        });
        Swal.fire({
          title: "Matéria(s) removida(s) com sucesso!",
          type: "success",
          confirmButtonText: "Ok",
        }).then((e) => {
          window.location.reload();
        });
      });
  };
};
