import { API_URL } from '../configs/config'

export function logar(usuario, senha) {
  return dispatch => {
    dispatch({ type: 'LOGIN_REQUEST' })
    fetch(`${API_URL}/login?email=${usuario}&senha=${senha}`)
      .then(T => {
        return T.json()
      })
      .then(response => {
        if (response.status === 'sucesso') {
          return dispatch({
            type: 'LOGIN_SUCCESS',
            payload: response,
          })
        } else {
          return dispatch({
            type: 'LOGIN_FAILURE',
            payload: 'error'
          })
        }
      })
      .catch(error => {
        return dispatch({
          type: 'LOGIN_FAILURE',
          payload: error
        })
      })
  }
}

export function deslogar(accessToken) {
  return dispatch => {
    fetch(`${API_URL}/logout?accessToken=${accessToken}`)
      .then(T => {
        return T.json()
      })
      .then(response => {
        dispatch({
          type : 'DESLOGAR'
        })
      })
      .catch(error => {
        dispatch({
          type : 'DESLOGAR'
        })
      })
  }

}