import Swal from "sweetalert2";
import { API_URL } from "../configs/config";

export const upload = (accessToken, file) => {
  return (dispatch) => {
    var formData = new FormData();
    formData.append("filez", file);

    dispatch({ type: "IMPORTAR_REQUEST" });
    fetch(`${API_URL}/importar`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Basic ${accessToken}`,
      },
    })
      .then((T) => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error("erro");
        }
        return T.json();
      })
      .then((response) => {
        if (response.error) {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: response.error,
            type: "error",
            confirmButtonText: "Ok",
          });
          dispatch({ type: "IMPORTAR_ERROR" });
        } else {
          Swal.fire({
            icon: "success",
            title: "Questões importadas com sucesso!",
            type: "success",
            confirmButtonText: "Ok",
          });
          dispatch({ type: "IMPORTAR_SUCCESS" });
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: error,
          type: "error",
          confirmButtonText: "Ok",
        });
        dispatch({ type: "IMPORTAR_ERROR" });
      });
  };
};

export const cadastrorapido = (accessToken, questoes) => {
  let headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/json",
  });

  const body = JSON.stringify(questoes);

  return (dispatch) => {
    dispatch({ type: "CADASTRORAPIDO_REQUEST" });
    fetch(`${API_URL}/cadastrorapido`, {
      method: "post",
      headers,
      body,
    })
      .then((T) => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error("erro");
        }
        return T.json();
      })
      .then((response) => {
        Swal.fire({
          title: "Questões cadastradas com sucesso!",
          type: "success",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "CADASTRORAPIDO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro ao tentar cadastrar as questões.",
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "CADASTRORAPIDO_FAILURE",
          payload: error,
        });
      })
      .then(() => {
        window.location.href = "/cadastrorapido";
        dispatch({
          type: "REDIRECT",
          payload: "/cadastrorapido",
        });
      });
  };
};
