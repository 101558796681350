import React from 'react'
import {
  //BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Principal from './views/Principal'

import Assuntos from './views/Assuntos'
import AssuntosCreate from './views/Assuntos/create'
import AssuntosShow from './views/Assuntos/show'
import AssuntosEdit from './views/Assuntos/edit'

import Bancas from './views/Bancas'
import BancasCreate from './views/Bancas/create'
import BancasShow from './views/Bancas/show'
import BancasEdit from './views/Bancas/edit'

import Cargos from './views/Cargos'
import CargosCreate from './views/Cargos/create'
import CargosShow from './views/Cargos/show'
import CargosEdit from './views/Cargos/edit'

import Orgaos from './views/Orgaos'
import OrgaosCreate from './views/Orgaos/create'
import OrgaosShow from './views/Orgaos/show'
import OrgaosEdit from './views/Orgaos/edit'

import Concursos from './views/Concursos'
import ConcursosCreate from './views/Concursos/create'
import ConcursosShow from './views/Concursos/show'
import ConcursosEdit from './views/Concursos/edit'

import Materias from './views/Materias'
import MateriasCreate from './views/Materias/create'
import MateriasShow from './views/Materias/show'
import MateriasEdit from './views/Materias/edit'

import Questoes from './views/Questoes'
import QuestoesCreate from './views/Questoes/create'
import QuestoesShow from './views/Questoes/show'
import QuestoesEdit from './views/Questoes/edit'

import Usuarios from './views/Usuarios'
import UsuariosCreate from './views/Usuarios/create'
import UsuariosShow from './views/Usuarios/show'
import UsuariosEdit from './views/Usuarios/edit'

import Importar from './views/Importar'

import CadastroRapido from './views/CadastroRapido'

import Exportar from './views/Exportar'

export default props => {
  return (
    <Switch>
      {/*--- PRINCIPAL --*/}
      <Route path="/principal" component={Principal} />

      {/*--- ASSUNTOS --*/}
      <Route exact path="/assuntos" component={Assuntos} />
      <Route exact path="/assuntos/create" component={AssuntosCreate} />
      <Route exact path="/assuntos/:id" component={AssuntosShow} />
      <Route exact path="/assuntos/:id/edit" component={AssuntosEdit} />

      {/*--- BANCAS --*/}
      <Route exact path="/bancas" component={Bancas} />
      <Route exact path="/bancas/create" component={BancasCreate} />
      <Route exact path="/bancas/:id" component={BancasShow} />
      <Route exact path="/bancas/:id/edit" component={BancasEdit} />

      {/*--- CARGOS --*/}
      <Route exact path="/cargos" component={Cargos} />
      <Route exact path="/cargos/create" component={CargosCreate} />
      <Route exact path="/cargos/:id" component={CargosShow} />
      <Route exact path="/cargos/:id/edit" component={CargosEdit} />

      {/*--- ORGAOS --*/}
      <Route exact path="/orgaos" component={Orgaos} />
      <Route exact path="/orgaos/create" component={OrgaosCreate} />
      <Route exact path="/orgaos/:id" component={OrgaosShow} />
      <Route exact path="/orgaos/:id/edit" component={OrgaosEdit} />

      {/*--- CONCURSOS --*/}
      <Route exact path="/concursos" component={Concursos} />
      <Route exact path="/concursos/create" component={ConcursosCreate} />
      <Route exact path="/concursos/:id" component={ConcursosShow} />
      <Route exact path="/concursos/:id/edit" component={ConcursosEdit} />

      {/*--- MATERIAS --*/}
      <Route exact path="/materias" component={Materias} />
      <Route exact path="/materias/create" component={MateriasCreate} />
      <Route exact path="/materias/:id" component={MateriasShow} />
      <Route exact path="/materias/:id/edit" component={MateriasEdit} />

      {/*--- QUESTOES --*/}
      <Route exact path="/questoes" component={Questoes} />
      <Route exact path="/questoes/create" component={QuestoesCreate} />
      <Route exact path="/questoes/:id" component={QuestoesShow} />
      <Route exact path="/questoes/:id/edit" component={QuestoesEdit} />

      {/*--- USUARIOS --*/}
      <Route exact path="/usuarios" component={Usuarios} />
      <Route exact path="/usuarios/create" component={UsuariosCreate} />
      <Route exact path="/usuarios/:id" component={UsuariosShow} />
      <Route exact path="/usuarios/:id/edit" component={UsuariosEdit} />

      {/*--- IMPORTAR ---*/}
      <Route exact path="/importar" component={Importar} />

      {/*--- CADASTRO RAPIDO ---*/}
      <Route exact path="/cadastrorapido" component={CadastroRapido} />

      {/*--- EXPORTAR ---*/}
      <Route exact path="/exportar" component={Exportar} />

      {/*--- REDIRECT TO --*/}
      <Redirect to="/principal" />
    </Switch>
  )
}
