import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getConcurso, deleteConcursos } from '../../actions/ConcursosAction'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import Loading from '../../components/Loading'
import { Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class ConcursosShow extends Component {

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getConcurso(id)
  }

  render() {
    const { concurso, loadConcursos } = this.props.concursos
    const { id } = this.props.match.params
    if (loadConcursos || !concurso) {
      return (
        <Loading></Loading>
      )
    }
    return (
      <div>
        <CabecalhoInterno editUrl={`/concursos/${id}/edit`} >
          <h3>Concurso</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Nome</div>
              <div className="value">{concurso.nome}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Cargo</div>
              <div className="value"><Link to={`/cargos/${concurso.cargo.id}`}>{concurso.cargo.nome}</Link></div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Banca</div>
              <div className="value"><Link to={`/bancas/${concurso.banca.id}`}>{concurso.banca.nome}</Link></div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Órgão</div>
              <div className="value"><Link to={`/bancas/${concurso.banca.id}`}>{concurso.orgao.nome}</Link></div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Ano</div>
              <div className="value">{concurso.ano}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Questões</div>
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="text-center" width="30px"></th>
                    <th className="text-center" width="30px">#</th>
                    <th>Enunciado</th>
                    <th>Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    concurso.questoes
                      ? concurso.questoes.map(questao =>
                        <tr key={questao.id}>
                          <td className="text-center">
                            <input value={concurso.id} type="checkbox" size="sm"/>
                          </td>
                          <td>{questao.id}</td>
                          <td><Link to={`/questoes/${questao.id}`}>{questao.enunciado}</Link></td>
                          <td>{questao.tipo}</td>
                        </tr>
                      )
                      : null
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = (state) =>
  ({
    concursos: state.concursos,
    login: state.login,
  })


const mapDispatchToProps = dispatch =>
  bindActionCreators({ getConcurso, deleteConcursos }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ConcursosShow)