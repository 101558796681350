import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Progress from '../../components/Progress'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import { upload } from '../../actions/ImportarAction'
import { Row, Col, FormGroup, FormControl, FormLabel, Button, Form, Table, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faDownload } from '@fortawesome/free-solid-svg-icons'
import './style.css'

class Importar extends Component {

  state = {
    file: null
  }


  addFile = e => {
    this.setState({ file: e.target.files[0] })
  }

  formSubmit = (e) => {
    e.preventDefault()

    const { file } = this.state
    const { accessToken } = this.props.login

    if (!file) {
      return false;
    }

    this.props.upload(accessToken, file);

  }

  render() {

    const { importando } = this.props.importar

    if (importando) {
      return (
        <Progress texto="Importando questões, aguarde" />
      )
    }
    return (
      <div>
        <CabecalhoInterno>
          <h3>Importar</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Row>
            <Col>
              <Alert variant="warning">
                <h5>Importe as questões utilizando um arquivo do tipo .csv seguindo o exemplo:</h5>
                <Table striped >
                <thead>
                  <tr>
                    <th>banca</th>
                    <th>cargo</th>
                    <th>orgao</th>
                    <th>uf</th>
                    <th>concurso</th>
                    <th>ano</th>
                    <th>materia</th>
                    <th>assunto</th>
                    <th>tipo</th>
                    <th>enunciado</th>
                    <th>resposta a</th>
                    <th>resposta b</th>
                    <th>resposta c</th>
                    <th>resposta d</th>
                    <th>resposta e</th>
                    <th>resposta correta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>nome da banca</td>
                    <td>nome do cargo</td>
                    <td>nome do órgão</td>
                    <td>RJ</td>
                    <td>nome do concurso</td>
                    <td>2020</td>
                    <td>nome da matéria</td>
                    <td>nome do assunto</td>
                    <td>múltipla escolha 5, múltipla escolha 4, certo ou errado</td>
                    <td>Quantas patas tem um pato?</td>
                    <td>0</td>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                    <td>4</td>
                    <td>c</td>
                  </tr>
                </tbody>
                </Table>
              </Alert>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
            <Form encType="multipart/form-data" onSubmit={this.formSubmit}>
            <Row>
              <Col>
                <FormGroup>
                    <FormLabel className="fileUploadContainer" htmlFor="fileUpload">
                    <FontAwesomeIcon className="fileUploadIcon" icon={faDownload} />
                    <FormControl
                      id="fileUpload"
                      name="fileUpload"
                      type="file"
                      accept=".csv"
                      onChange={this.addFile}
                    />
                  </FormLabel>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type="submit" variant="primary"><FontAwesomeIcon icon={faUpload}/> Importar</Button>
              </Col>
            </Row>
            </Form>
            </Col>
          </Row>
        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    importar: state.importar,
    login: state.login,
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ upload }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Importar)