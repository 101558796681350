import React, { Component } from 'react'
import './style.css'
import { bindActionCreators} from 'redux'
import { connect } from 'react-redux'
import { moverConfiguracoes } from '../../actions/ConfiguracoesAction'
import { deslogar } from '../../actions/LoginAction'
import { Navbar, Form, Button, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCog } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { Redirect } from "react-router"
import Swal from 'sweetalert2'

class Cabecalho extends Component {

  moverConfiguracoes = () => {
    this.props.moverConfiguracoes(this.props.configuracoes.estado)
  }

  deslogar = () => {
    const { accessToken } = this.props.login
    Swal.fire({
      title: `Sair?`,
      //text: `zzz.`,
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      confirmButtonColor: '#dc3545',
      focusCancel: true,
    }).then(e => {
      if (e.value) {
        this.props.deslogar(accessToken)
      }
    })
  }

  render() {
    const { usuario } = this.props.login

    if (!usuario) {
      return (
        <Redirect to={'/'} />
      )
    }

    return (
      <Navbar className="navbar border-bottom" sticky="top">
        <Navbar.Brand href="#home">
        </Navbar.Brand>
        <Form className="ml-auto" inline>
          {/*<FormControl type="text" placeholder="Localizar" className="mr-sm-2" />*/}
            <NavDropdown alignRight id="nav-dropdown" title={
              <FontAwesomeIcon icon={faUser} />
            }>
            {
              usuario ? [
                <NavDropdown.Header key="0">{usuario.nome}</NavDropdown.Header>,
                <NavDropdown.Item key="1" tag={Link} href={`/usuarios/${usuario.id}`} eventKey="1">
                  Propriedades
                </NavDropdown.Item>,
                <NavDropdown.Item key="2"tag={Link} href={`/usuarios/${usuario.id}/edit`} eventKey="2">
                  Alterar dados
                </NavDropdown.Item>,
                <NavDropdown.Divider key="3" />
              ] : []

            }
            <NavDropdown.Item key="4" onClick={this.deslogar} eventKey="4">Sair</NavDropdown.Item>
          </NavDropdown>
          <Button onClick={this.moverConfiguracoes} variant="link" className="faContainer"><FontAwesomeIcon icon={faCog}  /></Button>
        </Form>
      </Navbar>
    )
  }
}

const mapStateToProps = state =>
  ({
    configuracoes: state.configuracoes,
    login: state.login
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ moverConfiguracoes, deslogar }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Cabecalho)