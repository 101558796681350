import Swal from "sweetalert2";
import { API_URL } from "../configs/config";

export const getOrgao = (id) => {
  return (dispatch) => {
    dispatch({ type: "GET_ORGAO_REQUEST" });
    fetch(`${API_URL}/orgao/${id}`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_ORGAO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_ORGAO_FAILURE",
          payload: error,
        })
      );
  };
};

export const getOrgaos = () => {
  return (dispatch) => {
    dispatch({ type: "GET_ORGAOS_REQUEST" });
    fetch(`${API_URL}/orgao`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_ORGAOS_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_ORGAOS_FAILURE",
          payload: error,
        })
      );
  };
};

export const storeOrgao = (accessToken, opts = {}) => {
  let headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/json",
  });

  const body = JSON.stringify(opts);

  return (dispatch) => {
    dispatch({ type: "STORE_ORGAO_REQUEST" });
    fetch(`${API_URL}/orgao`, {
      method: "post",
      headers,
      body,
    })
      .then((T) => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error("erro");
        }
        return T.json();
      })
      .then((response) => {
        Swal.fire({
          title: "Órgão criada com sucesso!",
          type: "success",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "STORE_ORGAO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro ao tentar salvar o Órgão.",
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "STORE_ORGAO_FAILURE",
          payload: error,
        });
      })
      .then(() => {
        dispatch({
          type: "REDIRECT",
          payload: "/orgaos",
        });
      });
  };
};

export const updateOrgao = (accessToken, id, opts = {}) => {
  let headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/json",
  });
  const body = JSON.stringify(opts);
  return (dispatch) => {
    dispatch({ type: "UPDATE_ORGAO_REQUEST" });
    fetch(`${API_URL}/orgao/${id}`, {
      method: "put",
      headers,
      body,
    })
      .then((T) => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error("erro");
        }
        return T.json();
      })
      .then((response) => {
        Swal.fire({
          title: "Órgão editado com sucesso!",
          type: "success",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "UPDATE_ORGAO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro ao tentar editar Órgão.",
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: "Ok",
        });
        return dispatch({
          type: "UPDATE_ORGAO_FAILURE",
          payload: error,
        });
      })
      .then(() => {
        dispatch({
          type: "REDIRECT",
          payload: "/orgaos",
        });
      });
  };
};

export const deleteOrgaos = (accessToken, ids = null) => {
  let headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/json",
  });
  return (dispatch) => {
    dispatch({ type: "DELETE_ORGAO_REQUEST" });
    fetch(`${API_URL}/orgao/${ids}`, {
      method: "delete",
      headers,
    })
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "DELETE_ORGAO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "DELETE_ORGAO_FAILURE",
          payload: error,
        })
      )
      .then(() => {
        dispatch({
          type: "REDIRECT",
          payload: "/orgaos",
        });
        Swal.fire({
          title: "Órgão(s) removido(s) com sucesso!",
          type: "success",
          confirmButtonText: "Ok",
        }).then((e) => {
          window.location.reload();
        });
      });
  };
};
