const INITIAL_STATE = {
  comentarios: null,
  loadComentarios: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /** GET */
    case "GET_COMENTARIOS_REQUEST": {
      return { ...state, loadComentarios: true };
    }
    case "GET_COMENTARIOS_SUCCESS": {
      return { ...state, loadComentarios: false, comentarios: action.payload };
    }
    case "GET_COMENTARIOS_FAILURE": {
      return { ...INITIAL_STATE, loadComentarios: false };
    }

    /** DEFAULT */
    default: {
      return state;
    }
  }
}
