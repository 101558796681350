import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getMateria, deleteMaterias } from '../../actions/MateriasAction'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import Loading from '../../components/Loading'
import { Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class MateriasShow extends Component {

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getMateria(id)
  }

  render() {
    const { materia, loadMaterias } = this.props.materias
    const { id } = this.props.match.params
    if (loadMaterias || !materia) {
      return (
        <Loading></Loading>
      )
    }
    return (
      <div>
        <CabecalhoInterno editUrl={`/materias/${id}/edit`} >
          <h3>Materia</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Nome</div>
              <div className="value">{materia.nome}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col>
              <h5>Assuntos</h5>
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="text-center" width="30px"></th>
                    <th className="text-center" width="30px">#</th>
                    <th>Assunto</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    materia.assuntos
                      ? materia.assuntos.map(assunto =>
                        <tr key={assunto.id}>
                          <td className="text-center">
                            <input value={assunto.id} type="checkbox" size="sm"/>
                          </td>
                          <td>{assunto.id}</td>
                          <td><Link to={`/assuntos/${assunto.id}`}>{assunto.nome}</Link></td>
                        </tr>
                      )
                      : null
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = (state) =>
  ({
    materias: state.materias,
    login: state.login,
  })


const mapDispatchToProps = dispatch =>
  bindActionCreators({ getMateria, deleteMaterias }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MateriasShow)