import React from 'react'
import ReactDOM from 'react-dom';
import App from './app'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import HttpsRedirect from 'react-https-redirect';


import { store, persistor } from './store';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HttpsRedirect>
        <App></App>
      </HttpsRedirect>
    </PersistGate>
  </Provider>
  , document.getElementById('root')
)
