import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { storeUsuario } from "../../actions/UsuariosAction";
import Loading from "../../components/Loading";
import CabecalhoInterno from "../../components/CabecalhoInterno";
import ContainerInterno from "../../components/ContainerInterno";
import { Form, Col, Row, Button, Alert } from "react-bootstrap";
import { Redirect } from "react-router";

class UsuariosCreate extends Component {
  state = {
    formUsuario: "",
    formEmail: "",
    formNome: "",
    formPerfil: "ESTUDANTE",
    formNivel: "usuario",
    formSenha: "",
    formSenhaConfirm: "",
    erro: null,
    erroShow: false,
  };

  setError = (errorMessage = "Erro ao tentar criar o usuário.") => {
    this.setState({
      erro: errorMessage,
      erroShow: true,
    });
    document.getElementsByClassName("containerInterno")[0].scrollTop = 0;
    return false;
  };

  formSubmit = (e) => {
    e.preventDefault();
    const {
      formUsuario,
      formEmail,
      formNome,
      formSenha,
      formSenhaConfirm,
      formNivel,
      formPerfil,
    } = this.state;
    var errorMessage = "";
    if (!formUsuario) {
      errorMessage += "* Preencha o usuário.<br />";
    }
    if (!formEmail) {
      errorMessage += "* Preencha o e-mail.<br />";
    }
    if (!formNome) {
      errorMessage += "* Preencha o nome.<br />";
    }
    if (!formSenha) {
      errorMessage += "* Preencha a senha.<br />";
    }
    if (formSenha !== formSenhaConfirm) {
      errorMessage += "* A senha e confimação da senha devem ser iguais.<br />";
    }
    if (errorMessage !== "") {
      return this.setError(errorMessage);
    }
    const body = {
      usuario: formUsuario,
      nome: formNome,
      email: formEmail,
      senha: formSenha,
      perfil: formPerfil,
      nivel_de_acesso: formNivel,
    };
    console.log(body);
    this.props.storeUsuario(this.props.login.accessToken, body);
  };

  render() {
    const {
      formUsuario,
      formEmail,
      formNome,
      formNivel,
      formPerfil,
      formSenha,
      formSenhaConfirm,
      erro,
      erroShow,
    } = this.state;
    const { redirectTo } = this.props.redirect;

    if (this.props.usuarios.loadUsuarios) {
      return <Loading></Loading>;
    }
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    const niveis = ["usuario", "administrador"];

    return (
      <div>
        <CabecalhoInterno history={this.props.history}>
          <h3>Usuários</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Form onSubmit={this.formSubmit}>
            {erro && erroShow ? (
              <Alert
                variant="danger"
                onClick={() => this.setState({ erroShow: false })}
              >
                <div dangerouslySetInnerHTML={{ __html: erro }} />
              </Alert>
            ) : (
              []
            )}
            <Form.Group as={Row} controlId="formUsuario">
              <Form.Label column sm="1" className="text-right">
                Usuário
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formUsuario}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      formUsuario: e.target.value,
                      erroShow: false,
                    })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formEmail">
              <Form.Label column sm="1" className="text-right">
                E-mail
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formEmail}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      formEmail: e.target.value,
                      erroShow: false,
                    })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formNome">
              <Form.Label column sm="1" className="text-right">
                Nome
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formNome}
                  type="text"
                  onChange={(e) =>
                    this.setState({ formNome: e.target.value, erroShow: false })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPerfil">
              <Form.Label column sm="1" className="text-right">
                Perfil
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formPerfil}
                  as="select"
                  onChange={(e) => {
                    this.setState({
                      formPerfil: e.target.value,
                      erroShow: false,
                    });
                  }}
                >
                  <option value={"ESTUDANTE"} key={"1"}>
                    Estudante
                  </option>
                  <option value={"PROFESSOR"} key={"2"}>
                    Professor
                  </option>
                  <option value={"PROFISSIONAL"} key={"3"}>
                    Profissional de Direito
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formMateria">
              <Form.Label column sm="1" className="text-right">
                Nível de acesso
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  as="select"
                  value={formNivel}
                  onChange={(e) =>
                    this.setState({
                      formNivel: e.target.value,
                      erroShow: false,
                    })
                  }
                >
                  {niveis.map((nivel) => (
                    <option value={nivel} key={nivel}>
                      {nivel}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formSenha">
              <Form.Label column sm="1" className="text-right">
                Senha
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formSenha}
                  type="password"
                  onChange={(e) =>
                    this.setState({
                      formSenha: e.target.value,
                      erroShow: false,
                    })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formSenhaConfirm">
              <Form.Label column sm="1" className="text-right">
                Confirme a senha
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formSenhaConfirm}
                  type="password"
                  onChange={(e) =>
                    this.setState({
                      formSenhaConfirm: e.target.value,
                      erroShow: false,
                    })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm={{ span: 11, offset: 1 }}>
                <Button type="submit">Criar</Button>
              </Col>
            </Form.Group>
          </Form>
        </ContainerInterno>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usuarios: state.usuarios,
  login: state.login,
  redirect: state.redirect,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ storeUsuario }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsuariosCreate);
