import React from 'react'
import './style.css'
import { Spinner } from 'react-bootstrap'

export default () => {
  return (
    <div className="loading-container">
      <Spinner animation="border" variant="primary" size="lg" />
    </div>
  )
}