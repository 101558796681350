import React from 'react'
import './style.css'
import { ProgressBar } from 'react-bootstrap'

export default (props) => {
  return (
    <div className="progress-container">
      <ProgressBar animated now={20} />
      <p className="progress-text animated-dots">{props.texto}</p>
    </div>
  )
}