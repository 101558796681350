import React from "react";
import "./style.css";
import { Container } from "react-bootstrap";

export default (props) => {
  return (
    <Container
      style={props.style}
      fluid
      className={["containerInterno", props.className].join(" ")}
    >
      {props.children}
    </Container>
  );
};
