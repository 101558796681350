import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { storeConcurso } from "../../actions/ConcursosAction";
import { getBancas } from "../../actions/BancasAction";
import { getOrgaos } from "../../actions/OrgaosAction";
import { getCargos } from "../../actions/CargosAction";
import Loading from "../../components/Loading";
import CabecalhoInterno from "../../components/CabecalhoInterno";
import ContainerInterno from "../../components/ContainerInterno";
import { Form, Col, Row, Button, Alert } from "react-bootstrap";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

class ConcursosCreate extends Component {
  state = {
    formNome: "",
    formBanca: 0,
    formOrgao: 0,
    formCargo: 0,
    formAno: 2020,
    erro: null,
    erroShow: false,
  };

  componentDidMount() {
    this.props.getBancas();
    this.props.getOrgaos();
    this.props.getCargos();
  }

  setError = (errorMessage = "Erro ao tentar criar o concurso.") => {
    this.setState({
      erro: errorMessage,
      erroShow: true,
    });
    document.getElementsByClassName("containerInterno")[0].scrollTop = 0;
    return false;
  };

  formSubmit = (e) => {
    e.preventDefault();
    const { formNome, formBanca, formOrgao, formAno, formCargo } = this.state;
    var errorMessage = "";
    if (!formNome) {
      errorMessage += "* Preencha o nome.<br />";
    }
    if (!formBanca) {
      errorMessage += "* Escolha a banca.<br />";
    }
    if (!formOrgao) {
      errorMessage += "* Escolha o órgão.<br />";
    }
    if (!formAno) {
      errorMessage += "* Preencha o ano.<br />";
    }
    if (errorMessage !== "") {
      return this.setError(errorMessage);
    }
    const body = {
      nome: formNome,
      ano: formAno,
      idBanca: +formBanca,
      idOrgao: +formOrgao,
      idCargo: +formCargo,
    };
    this.props.storeConcurso(this.props.login.accessToken, body);
  };

  render() {
    const {
      concursos: { loadConcursos },
      cargos: { cargos, loadCargos },
      bancas: { bancas, loadBancas },
      orgaos: { orgaos, loadOrgaos },
    } = this.props;
    const {
      formNome,
      formBanca,
      formAno,
      formOrgao,
      formCargo,
      erro,
      erroShow,
    } = this.state;
    const { redirectTo } = this.props.redirect;
    if (loadConcursos || loadBancas || loadOrgaos || loadCargos) {
      return <Loading></Loading>;
    }
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    return (
      <div>
        <CabecalhoInterno history={this.props.history}>
          <h3>Concursos</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Form onSubmit={this.formSubmit}>
            {erro && erroShow ? (
              <Alert
                variant="danger"
                onClick={() => this.setState({ erroShow: false })}
              >
                <div dangerouslySetInnerHTML={{ __html: erro }} />
              </Alert>
            ) : (
              []
            )}
            <Form.Group as={Row} controlId="formNome">
              <Form.Label column sm="1" className="text-right">
                Concurso
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formNome}
                  type="text"
                  onChange={(e) =>
                    this.setState({ formNome: e.target.value, erroShow: false })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formBanca">
              <Form.Label column sm="1" className="text-right">
                Banca
              </Form.Label>
              <Col sm="11">
                {bancas && bancas.length > 0 ? (
                  <Form.Control
                    as="select"
                    value={formBanca}
                    onChange={(e) =>
                      this.setState({
                        formBanca: e.target.value,
                        erroShow: false,
                      })
                    }
                  >
                    <option value="0" key="0">
                      -- Selecione uma banca --
                    </option>
                    {bancas.map((banca) => (
                      <option value={banca.id} key={banca.id}>
                        {banca.nome}
                      </option>
                    ))}
                  </Form.Control>
                ) : (
                  <Alert variant="warning">
                    Não há bancas.{" "}
                    <Link to={`/bancas/create`} className="text-primary">
                      Clique aqui
                    </Link>{" "}
                    para cadastrar.
                  </Alert>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formCargo">
              <Form.Label column sm="1" className="text-right">
                Cargo
              </Form.Label>
              <Col sm="11">
                {bancas && bancas.length > 0 ? (
                  <Form.Control
                    as="select"
                    value={formCargo}
                    onChange={(e) =>
                      this.setState({
                        formCargo: e.target.value,
                        erroShow: false,
                      })
                    }
                  >
                    <option value="0" key="0">
                      -- Selecione um cargo --
                    </option>
                    {cargos.map((cargo) => (
                      <option value={cargo.id} key={cargo.id}>
                        {cargo.nome}
                      </option>
                    ))}
                  </Form.Control>
                ) : (
                  <Alert variant="warning">
                    Não há cargos.{" "}
                    <Link to={`/cargos/create`} className="text-primary">
                      Clique aqui
                    </Link>{" "}
                    para cadastrar.
                  </Alert>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formOrgao">
              <Form.Label column sm="1" className="text-right">
                Órgão
              </Form.Label>
              <Col sm="11">
                {orgaos && orgaos.length > 0 ? (
                  <Form.Control
                    as="select"
                    value={formOrgao}
                    onChange={(e) =>
                      this.setState({
                        formOrgao: e.target.value,
                        erroShow: false,
                      })
                    }
                  >
                    <option value="0" key="0">
                      -- Selecione um órgão --
                    </option>
                    {orgaos.map((orgao) => (
                      <option value={orgao.id} key={orgao.id}>
                        {orgao.nome}
                      </option>
                    ))}
                  </Form.Control>
                ) : (
                  <Alert variant="warning">
                    Não há órgãos.{" "}
                    <Link to={`/bancas/create`} className="text-primary">
                      Clique aqui
                    </Link>{" "}
                    para cadastrar.
                  </Alert>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formAno">
              <Form.Label column sm="1" className="text-right">
                Ano
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formAno}
                  type="number"
                  min="1900"
                  max="2020"
                  step="1"
                  onChange={(e) =>
                    this.setState({ formAno: e.target.value, erroShow: false })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm={{ span: 11, offset: 1 }}>
                <Button type="submit">Criar</Button>
              </Col>
            </Form.Group>
          </Form>
        </ContainerInterno>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  concursos: state.concursos,
  login: state.login,
  bancas: state.bancas,
  orgaos: state.orgaos,
  cargos: state.cargos,
  redirect: state.redirect,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { storeConcurso, getBancas, getOrgaos, getCargos },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConcursosCreate);
