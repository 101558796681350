import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getAssuntos, deleteAssuntos } from '../../actions/AssuntosAction'
import Loading from '../../components/Loading'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import { Table, Alert, Col, Row } from 'react-bootstrap'
import MenuTabela from '../../components/MenuTabela'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'

class Assuntos extends Component {

  state = {
    assuntos: [],
    assuntosSelecionados: []
  }

  componentDidMount() {
    this.props.getAssuntos()
  }

  selecionarAssuntos = e => {
    const id = e.target.value
    const assuntosSelecionados = { ...this.state.assuntosSelecionados, [id]: e.target.checked }
    this.setState({'assuntosSelecionados': assuntosSelecionados})
  }

  removerAssuntos = e => {
    const { assuntosSelecionados } = this.state
    var assuntos = Object.keys(assuntosSelecionados).map((key) => {
      if (assuntosSelecionados[key]) {
        return key
      }
      return false
    })
    assuntos = assuntos.filter(Boolean)
    Swal.fire({
      title: `Deseja realmente remover o${assuntos.length !== 1 ? 's' : ''} assunto${assuntos.length !== 1 ? 's' : ''}?`,
      text: `${assuntos.length} selecionado${assuntos.length  !== 1 ? 's':''}.`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#dc3545',
      focusCancel: true,
    }).then(e => {
      if (e.value) {
        this.props.deleteAssuntos(this.props.login.accessToken, assuntos.join())
      }
    })
  }

  render() {
    const { assuntosSelecionados } = this.state
    const { assuntos, loadAssuntos } = this.props.assuntos
    if (loadAssuntos) {
      return (
        <Loading></Loading>
      )
    }
    var selecionado = Object.keys(assuntosSelecionados).find((key) => {
      return assuntosSelecionados[key]
    })

    return (
      <div>
        <CabecalhoInterno addUrl="/assuntos/create">
          <h3>Assuntos</h3>
        </CabecalhoInterno>
        <ContainerInterno style={{height: 'calc(100vh - 230px)'}}>
          <Row>
            <Col className="text-left">

            </Col>
            <Col className="text-right">
              {
                selecionado
                  ? <MenuTabela selecionado={selecionado} remove={this.removerAssuntos}/>
                  : []
              }
            </Col>
          </Row>
          {
            assuntos && assuntos.length > 0
              ?
              <div className="tableContainer">
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="text-center" width="30px"></th>
                    <th className="text-center" width="30px">#</th>
                    <th>Assunto</th>
                    <th>Matéria</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    assuntos.map(assunto =>
                      <tr key={assunto.id}>
                        <td className="text-center">
                          <input value={assunto.id} type="checkbox" size="sm" onChange={e => this.selecionarAssuntos(e)}/>
                        </td>
                        <td>{assunto.id}</td>
                        <td><Link to={`/assuntos/${assunto.id}`}>{assunto.nome}</Link></td>
                        <td>{assunto.materia ? <Link to={`/materias/${assunto.materia.id}`}>{assunto.materia.nome}</Link> : ''}</td>
                      </tr>
                    )
                  }
                </tbody>
                </Table>
                </div>
              :
              <Alert variant="warning">Não há assuntos. <Link to={`/assuntos/create`} className="text-primary">Clique aqui</Link> para cadastrar.</Alert>
          }

        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    assuntos: state.assuntos,
    login: state.login,
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getAssuntos, deleteAssuntos }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Assuntos)