import Swal from 'sweetalert2'
import { API_URL } from '../configs/config'

export const getCargo = (id) => {
  return dispatch => {
    dispatch({ type: 'GET_CARGO_REQUEST' })
    fetch(`${API_URL}/cargo/${id}`)
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'GET_CARGO_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'GET_CARGO_FAILURE',
        payload: error
      }))
  }
}

export const getCargos = () => {
  return dispatch => {
    dispatch({ type: 'GET_CARGOS_REQUEST' })
    fetch(`${API_URL}/cargo`)
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'GET_CARGOS_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'GET_CARGOS_FAILURE',
        payload: error
      }))
  }
}

export const storeCargo = (accessToken, opts = {}) => {

  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })

  const body = JSON.stringify(opts)

  return dispatch => {
    dispatch({ type: 'STORE_CARGO_REQUEST' })
    fetch(`${API_URL}/cargo`, {
      method: 'post',
      headers,
      body,
    })
      .then(T => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error('erro')
        }
        return T.json()
      })
      .then(response => {
        Swal.fire({
          title: 'Cargo criada com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'STORE_CARGO_SUCCESS',
          payload: response
        })
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao tentar salvar o Cargo.',
          type: 'error',
          confirmButtonColor: '#dc3545',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'STORE_CARGO_FAILURE',
          payload: error
        })
      })
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/cargos'
        })
      })
  }
}

export const updateCargo = (accessToken, id, opts = {}) => {
  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })
  const body = JSON.stringify(opts)
  return dispatch => {
    dispatch({ type: 'UPDATE_CARGO_REQUEST' })
    fetch(`${API_URL}/cargo/${id}`, {
      method: 'put',
      headers,
      body,
    })
      .then(T => {
        if (T.status !== 200 && T.status !== 201) {
          throw new Error('erro')
        }
        return T.json()
      })
      .then(response => {
        Swal.fire({
          title: 'Cargo editada com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'UPDATE_CARGO_SUCCESS',
          payload: response
        })
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao tentar editar o Cargo.',
          type: 'error',
          confirmButtonColor: '#dc3545',
          confirmButtonText: 'Ok'
        })
        return dispatch({
          type: 'UPDATE_CARGO_FAILURE',
          payload: error
        })
      })
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/cargos'
        })
      })
  }
}

export const deleteCargos= (accessToken, ids = null) => {
  let headers = new Headers({
    'Authorization': `Basic ${accessToken}`,
    'Content-Type': 'application/json'
  })
  return dispatch => {
    dispatch({ type: 'DELETE_CARGO_REQUEST' })
    fetch(`${API_URL}/cargo/${ids}`, {
      method: 'delete',
      headers
    })
      .then(T => {
        return T.json()
      })
      .then(response => {
        return dispatch({
          type: 'DELETE_CARGO_SUCCESS',
          payload: response
        })
      })
      .catch(error => dispatch({
        type: 'DELETE_CARGO_FAILURE',
        payload: error
      }))
      .then(() => {
        dispatch({
          type: 'REDIRECT',
          payload: '/cargos'
        })
        Swal.fire({
          title: 'Cargo(s) removida(s) com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        }).then(e => {
          window.location.reload();
        })
      })
  }
}