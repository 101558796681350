import React, { Component } from 'react'
import style from './style.module.css'

import { bindActionCreators} from 'redux'
import { connect } from 'react-redux'

import Logo from '../Logo'
import MenuLateral from '../MenuLateral'

import { moverAreaLateral } from '../../actions/AreaLateralAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'

class AreaLateral extends Component {

  moverAreaLateral = () => {
    this.props.moverAreaLateral(this.props.areaLateral.estado)
  }

  render() {
    return (
      <div className={style.areaLateral}>
        <Logo></Logo>
        <MenuLateral></MenuLateral>
        {
          this.props.areaLateral.estado === 'EXPANDIDO'
            ? <button onClick={this.moverAreaLateral} href="#" className={style.btnRetractExpand}>
                <FontAwesomeIcon size="sm" icon={faAngleDoubleLeft} />
              <div className={style.textRecolherMenu}>Recolher Menu</div>
              </button>
            : <button onClick={this.moverAreaLateral} href="#" className={style.btnRetractExpand}>
                <FontAwesomeIcon size="sm" icon={faAngleDoubleRight} />
              </button>
        }
      </div>
    )
  }
}


const mapStateToProps = state =>
  ({
    areaLateral: state.areaLateral
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ moverAreaLateral }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AreaLateral)