import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { storeQuestao } from "../../actions/QuestoesAction";
import { getMaterias } from "../../actions/MateriasAction";
import { getConcursos } from "../../actions/ConcursosAction";
import Loading from "../../components/Loading";
import CabecalhoInterno from "../../components/CabecalhoInterno";
import ContainerInterno from "../../components/ContainerInterno";
import { Form, Col, Row, Button, Alert } from "react-bootstrap";
import Resposta from "../../components/Resposta";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";

class QuestoesCreate extends Component {
  state = {
    formEnunciado: "",
    formFeedback: "",
    formMateria: 0,
    formAssunto: 0,
    formConcurso: 0,
    formOpcaoCorreta: null,
    respostas: ["", "", "", "", ""],
    erro: null,
    erroShow: false,
    formTipo: "múltipla escolha 5",
    editorState: null,
  };

  componentDidMount() {
    this.props.getMaterias();
    this.props.getConcursos();
    document.addEventListener("keydown", this.onKeyPress, false);

    const html = "<p>Hey this <strong>editor</strong> rocks 😀</p>";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPress, false);
  }

  setError = (errorMessage = "Erro ao tentar criar a questão.") => {
    this.setState({
      erro: errorMessage,
      erroShow: true,
    });
    document.getElementsByClassName("containerInterno")[0].scrollTop = 0;
    return false;
  };

  formSubmit = (e) => {
    e.preventDefault();
    const {
      /*formEnunciado,*/ formMateria,
      formAssunto,
      formConcurso,
      respostas,
      formOpcaoCorreta,
      formFeedback,
      formTipo,
      editorState,
    } = this.state;

    var formEnunciado = "";
    if (editorState) {
      formEnunciado = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
    }

    var errorMessage = "";

    if (!formEnunciado) {
      errorMessage += "* Preencha o enunciado.<br />";
    }

    if (!formMateria) {
      errorMessage += "* Selecione uma matéria.<br />";
    }

    if (!formAssunto) {
      errorMessage += "* Selecione um assunto.<br />";
    }

    if (!formConcurso) {
      errorMessage += "* Selecione um concurso.<br />";
    }

    if (!formOpcaoCorreta) {
      errorMessage += "* Selecione uma resposta correta.<br />";
    }

    if (!formTipo) {
      errorMessage += "* Selecione um tipo.<br />";
    }

    if (errorMessage !== "") {
      return this.setError(errorMessage);
    }

    const body = {
      enunciado: formEnunciado,
      feedback: formFeedback,
      idMateria: +formMateria,
      idAssunto: +formAssunto,
      idConcurso: +formConcurso,
      opcaoCorreta: +formOpcaoCorreta,
      tipo: formTipo,
      respostas,
    };
    this.props.storeQuestao(this.props.login.accessToken, body);
  };

  selecionarResposta = (resposta) => {
    this.setState({ formOpcaoCorreta: resposta });
  };

  change = (e) => {
    const id = e.target.id.split("-")[1];
    var respostas = this.state.respostas;
    respostas[id] = e.target.value;
    this.setState({ respostas: [...respostas] });
  };

  renderRespostas = (respostas) => {
    const tipo = this.state.formTipo;
    switch (tipo) {
      case "múltipla escolha 5": {
        return this.renderRespostasMultiplaEscolha5(respostas);
      }
      case "múltipla escolha 4": {
        return this.renderRespostasMultiplaEscolha4(respostas);
      }
      case "certo ou errado": {
        return this.renderRespostasCertoOuErrado(respostas);
      }
      default: {
        return this.renderRespostasMultiplaEscolha5(respostas);
      }
    }
  };

  renderRespostasMultiplaEscolha5 = (respostas) => {
    const opcaoCorreta = this.state.formOpcaoCorreta;
    return (
      <>
        <Resposta
          id={0}
          onKeyPress={(e) => this.onKeyPress(e)}
          checked={1 === opcaoCorreta ? true : false}
          changeResposta={this.change}
          selecionarResposta={this.selecionarResposta}
          key={0}
          index={0}
        >
          {respostas[0].texto
            ? respostas[0].texto
            : respostas[0]
            ? respostas[0]
            : ""}
        </Resposta>
        <Resposta
          id={1}
          onKeyPress={(e) => this.onKeyPress(e)}
          checked={2 === opcaoCorreta ? true : false}
          changeResposta={this.change}
          selecionarResposta={this.selecionarResposta}
          key={1}
          index={1}
        >
          {respostas[1].texto
            ? respostas[1].texto
            : respostas[1]
            ? respostas[1]
            : ""}
        </Resposta>
        <Resposta
          id={2}
          onKeyPress={(e) => this.onKeyPress(e)}
          checked={3 === opcaoCorreta ? true : false}
          changeResposta={this.change}
          selecionarResposta={this.selecionarResposta}
          key={2}
          index={2}
        >
          {respostas[2].texto
            ? respostas[2].texto
            : respostas[2]
            ? respostas[2]
            : ""}
        </Resposta>
        <Resposta
          id={3}
          onKeyPress={(e) => this.onKeyPress(e)}
          checked={4 === opcaoCorreta ? true : false}
          changeResposta={this.change}
          selecionarResposta={this.selecionarResposta}
          key={3}
          index={3}
        >
          {respostas[3].texto
            ? respostas[3].texto
            : respostas[3]
            ? respostas[3]
            : ""}
        </Resposta>
        <Resposta
          id={4}
          onKeyPress={(e) => this.onKeyPress(e)}
          checked={5 === opcaoCorreta ? true : false}
          changeResposta={this.change}
          selecionarResposta={this.selecionarResposta}
          key={4}
          index={4}
        >
          {respostas[4].texto
            ? respostas[4].texto
            : respostas[4]
            ? respostas[4]
            : ""}
        </Resposta>
      </>
    );
  };

  renderRespostasMultiplaEscolha4 = (respostas) => {
    const opcaoCorreta = this.state.formOpcaoCorreta;
    return (
      <>
        <Resposta
          id={0}
          onKeyPress={(e) => this.onKeyPress(e)}
          checked={1 === opcaoCorreta ? true : false}
          changeResposta={this.change}
          selecionarResposta={this.selecionarResposta}
          key={0}
          index={0}
        >
          {respostas[0].texto
            ? respostas[0].texto
            : respostas[0]
            ? respostas[0]
            : ""}
        </Resposta>
        <Resposta
          id={1}
          onKeyPress={(e) => this.onKeyPress(e)}
          checked={2 === opcaoCorreta ? true : false}
          changeResposta={this.change}
          selecionarResposta={this.selecionarResposta}
          key={1}
          index={1}
        >
          {respostas[1].texto
            ? respostas[1].texto
            : respostas[1]
            ? respostas[1]
            : ""}
        </Resposta>
        <Resposta
          id={2}
          onKeyPress={(e) => this.onKeyPress(e)}
          checked={3 === opcaoCorreta ? true : false}
          changeResposta={this.change}
          selecionarResposta={this.selecionarResposta}
          key={2}
          index={2}
        >
          {respostas[2].texto
            ? respostas[2].texto
            : respostas[2]
            ? respostas[2]
            : ""}
        </Resposta>
        <Resposta
          id={3}
          onKeyPress={(e) => this.onKeyPress(e)}
          checked={4 === opcaoCorreta ? true : false}
          changeResposta={this.change}
          selecionarResposta={this.selecionarResposta}
          key={3}
          index={3}
        >
          {respostas[3].texto
            ? respostas[3].texto
            : respostas[3]
            ? respostas[3]
            : ""}
        </Resposta>
      </>
    );
  };

  renderRespostasCertoOuErrado = (respostas) => {
    const opcaoCorreta = this.state.formOpcaoCorreta;
    return (
      <>
        <Resposta
          id={0}
          type="certo ou errado"
          onKeyPress={(e) => this.onKeyPress(e)}
          checked={1 === opcaoCorreta ? true : false}
          changeResposta={this.change}
          selecionarResposta={this.selecionarResposta}
          key={0}
          index={0}
        >
          Certo
        </Resposta>
        <Resposta
          id={1}
          type="certo ou errado"
          onKeyPress={(e) => this.onKeyPress(e)}
          checked={2 === opcaoCorreta ? true : false}
          changeResposta={this.change}
          selecionarResposta={this.selecionarResposta}
          key={1}
          index={1}
        >
          Errado
        </Resposta>
      </>
    );
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {
    const {
      questoes: { loadQuestoes },
      materias: { materias, loadMaterias },
      concursos: { concursos, loadConcursos },
    } = this.props;
    const {
      formEnunciado,
      formFeedback,
      formMateria,
      formAssunto,
      formConcurso,
      erro,
      erroShow,
      formTipo,
    } = this.state;
    const { redirectTo } = this.props.redirect;

    const materia =
      materias && materias.length > 0
        ? materias.find((materia) => {
            return materia.id === +formMateria;
          })
        : {};

    const assuntos = materia ? materia.assuntos : [];

    if (loadQuestoes || loadMaterias || loadConcursos) {
      return <Loading></Loading>;
    }
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    return (
      <div>
        <CabecalhoInterno history={this.props.history}>
          <h3>Questões</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Form onSubmit={this.formSubmit}>
            {erro && erroShow ? (
              <Alert
                variant="danger"
                onClick={() => this.setState({ erroShow: false })}
              >
                <div dangerouslySetInnerHTML={{ __html: erro }} />
              </Alert>
            ) : (
              []
            )}
            <Form.Group as={Row} controlId="formMateria">
              <Form.Label column sm="1" className="text-right">
                Matéria
              </Form.Label>
              <Col sm="11">
                {materias && materias.length > 0 ? (
                  <Form.Control
                    as="select"
                    value={formMateria}
                    onChange={(e) =>
                      this.setState({
                        formMateria: e.target.value,
                        formAssunto: null,
                        erroShow: false,
                      })
                    }
                  >
                    <option value="0" key="0">
                      -- Selecione uma matéria --
                    </option>
                    {materias.map((materia) => (
                      <option value={materia.id} key={materia.id}>
                        {materia.nome}
                      </option>
                    ))}
                  </Form.Control>
                ) : (
                  <Alert variant="warning">
                    Não há matérias.{" "}
                    <Link to={`/materias/create`} className="text-primary">
                      Clique aqui
                    </Link>{" "}
                    para cadastrar.
                  </Alert>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formAssunto">
              <Form.Label column sm="1" className="text-right">
                Assunto
              </Form.Label>
              <Col sm="11">
                {assuntos && assuntos.length > 0 ? (
                  <Form.Control
                    as="select"
                    value={formAssunto ? formAssunto : 0}
                    onChange={(e) =>
                      this.setState({
                        formAssunto: e.target.value,
                        erroShow: false,
                      })
                    }
                  >
                    <option value="0" key="0">
                      -- Selecione um assunto --
                    </option>
                    {assuntos.map((assunto) => (
                      <option value={assunto.id} key={assunto.id}>
                        {assunto.nome}
                      </option>
                    ))}
                  </Form.Control>
                ) : (
                  <Alert variant="warning">
                    Não há assuntos.{" "}
                    <Link to={`/assuntos/create`} className="text-primary">
                      Clique aqui
                    </Link>{" "}
                    para cadastrar.
                  </Alert>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formConcurso">
              <Form.Label column sm="1" className="text-right">
                Concurso
              </Form.Label>
              <Col sm="11">
                {concursos && concursos.length ? (
                  <Form.Control
                    as="select"
                    value={formConcurso}
                    onChange={(e) =>
                      this.setState({
                        formConcurso: e.target.value,
                        erroShow: false,
                      })
                    }
                  >
                    <option value="0" key="0">
                      -- Selecione um concurso --
                    </option>
                    {concursos.map((concurso) => (
                      <option value={concurso.id} key={concurso.id}>
                        {concurso.nome} - {concurso.banca.nome} - {concurso.ano}
                      </option>
                    ))}
                  </Form.Control>
                ) : (
                  <Alert variant="warning">
                    Não há concursos.{" "}
                    <Link to={`/concursos/create`} className="text-primary">
                      Clique aqui
                    </Link>{" "}
                    para cadastrar.
                  </Alert>
                )}
              </Col>
            </Form.Group>

            {/*<Form.Group as={Row} controlId="formEnunciado">
              <Form.Label column sm="1" className="text-right">
                Questão
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formEnunciado}
                  as="textarea"
                  onChange={e => this.setState({ formEnunciado: e.target.value, erroShow: false })}
                />
              </Col>
              </Form.Group>*/}

            <Form.Group as={Row} controlId="formEnunciado">
              <Form.Label column sm="1" className="text-right">
                Questão
              </Form.Label>
              <Col sm="11">
                <Editor
                  editorState={this.state.editorState}
                  toolbarClassName="wysiwygToolbar"
                  wrapperClassName="wysiwygTexto"
                  editorClassName="wysiwygEditor"
                  onEditorStateChange={this.onEditorStateChange}
                  toolbar={{
                    options: ["inline", "textAlign", "image"],
                    inline: {
                      options: ["bold", "italic", "underline", "strikethrough"],
                    },
                  }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formFeedback">
              <Form.Label column sm="1" className="text-right">
                Feedback
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formFeedback}
                  as="textarea"
                  onChange={(e) =>
                    this.setState({
                      formFeedback: e.target.value,
                      erroShow: false,
                    })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formTipo">
              <Form.Label column sm="1" className="text-right">
                Tipo de questão
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  as="select"
                  value={formTipo}
                  onChange={(e) =>
                    this.setState({ formTipo: e.target.value, erroShow: false })
                  }
                >
                  <option value="múltipla escolha 5">
                    Múltipla escolha com 5 opções
                  </option>
                  <option value="múltipla escolha 4">
                    Múltipla escolha com 4 opções
                  </option>
                  <option value="certo ou errado">Certo ou errado</option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Row className="mb-3">
              <Col sm={1} className="text-right">
                Respostas
              </Col>
            </Row>

            {this.renderRespostas(this.state.respostas)}

            <Form.Group as={Row}>
              <Col sm={{ span: 11, offset: 1 }}>
                <Button type="submit" tabIndex="99">
                  Criar
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </ContainerInterno>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  questoes: state.questoes,
  login: state.login,
  materias: state.materias,
  concursos: state.concursos,
  redirect: state.redirect,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ storeQuestao, getMaterias, getConcursos }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuestoesCreate);
