import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { storeAssunto } from '../../actions/AssuntosAction'
import { getMaterias } from '../../actions/MateriasAction'
import Loading from '../../components/Loading'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import { Form, Col, Row, Button, Alert } from 'react-bootstrap'
import { Redirect } from "react-router"
import { Link } from 'react-router-dom'

class AssuntosCreate extends Component {

  state = {
    formNome: '',
    formMateria: 0,
    erro: null,
    erroShow: false
  };

  componentDidMount() {
    this.props.getMaterias()
  }

  setError = (errorMessage = 'Erro ao tentar criar o assunto.') => {
    this.setState({
      erro: errorMessage,
      erroShow: true
    })
    document.getElementsByClassName('containerInterno')[0].scrollTop = 0
    return false
  }

  formSubmit = (e) => {
    e.preventDefault()
    const { formNome, formMateria } = this.state
    var errorMessage = ""
    if (!formNome) {
      errorMessage += "* Preencha o nome.<br />"
    }
    if (!formMateria) {
      errorMessage += "* Escolha a matéria.<br />"
    }
    if (errorMessage !== "") {
      return this.setError(errorMessage)
    }
    const body = {
      nome: formNome,
      idMateria: +formMateria
    }
    this.props.storeAssunto(this.props.login.accessToken, body)
  }

  render() {

    const { assuntos: { loadAssuntos }, materias: { materias, loadMaterias } } = this.props
    const { formNome, formMateria, erro, erroShow } = this.state
    const { redirectTo } = this.props.redirect

    if (loadAssuntos || loadMaterias) {
      return (
        <Loading></Loading>
      )
    }

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <div>
        <CabecalhoInterno history={this.props.history}>
          <h3>Assuntos</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Form onSubmit={this.formSubmit}>
            {erro && erroShow ? <Alert variant="danger" onClick={() => this.setState({erroShow: false})} ><div dangerouslySetInnerHTML={{ __html: erro }} /></Alert> : []}
            <Form.Group as={Row} controlId="formNome">
              <Form.Label column sm="1" className="text-right">
                Assunto
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formNome}
                  type="text"
                  onChange={e => this.setState({ formNome: e.target.value, erroShow: false })}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formMateria">
              <Form.Label column sm="1" className="text-right">
                Matérias
              </Form.Label>
              <Col sm="11">
                {
                  materias && materias.length
                    ?
                    <Form.Control as="select" value={formMateria} onChange={e => this.setState({ formMateria: e.target.value, erroShow: false })}>
                      <option value="0" key="0">-- Selecione uma matéria --</option>
                      {
                        materias.map(materia =>
                          <option value={materia.id} key={materia.id}>{materia.nome}</option>
                        )
                      }
                    </Form.Control>
                    :
                    <Alert variant="warning">Não há materias. <Link to={`/materias/create`} className="text-primary">Clique aqui</Link> para cadastrar.</Alert>
                }
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm={{ span: 11, offset: 1 }}>
                <Button type="submit">Criar</Button>
              </Col>
            </Form.Group>

          </Form>
        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    assuntos: state.assuntos,
    login: state.login,
    materias: state.materias,
    redirect: state.redirect
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ storeAssunto, getMaterias }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AssuntosCreate)