import React, { Component } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import './style.css'



export default class Resposta extends Component {
  render() {
    var title = String.fromCharCode(65 + this.props.index);
    const { checked } = this.props

    if (this.props.type === 'certo ou errado') {
      title = ''
    } else { 
      title = title + ')'
    }

    return (
      <Form.Group as={Row} controlId={`formResposta-${this.props.index}`}>
        <Form.Label
          column sm="1"
          className="text-right">
          {title}
        </Form.Label>
        <Col sm="auto pr-0">
          <Form.Control
            type="radio"
            name="resposta"
            checked={checked}
            className="respostaCorreta"
            onChange={() => this.props.selecionarResposta(this.props.index+1)}
          />
        </Col>
        {
          this.props.type === 'certo ou errado'
            ? (
              <Col>
                {this.props.index === 0 ? <b style={{lineHeight: '38px'}}>CERTO</b> : <b style={{lineHeight: '38px'}}>ERRADO</b>}
              </Col>
            )
            : (
              <Col>
                <Form.Control
                  as="textarea"
                  className="resposta"
                  value={this.props.children}
                  tabIndex={this.props.index+1}
                  onChange={this.props.changeResposta}
                />
              </Col>
            )
        } 
        
      </Form.Group>
    )
  }
}