import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getCargo, deleteCargos } from '../../actions/CargosAction'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import Loading from '../../components/Loading'
import { Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class CargosShow extends Component {

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getCargo(id)
  }

  render() {
    const { cargo, loadCargos } = this.props.cargos
    const { id } = this.props.match.params

    if (loadCargos || !cargo) {
      return (
        <Loading></Loading>
      )
    }

    return (
      <div>
        <CabecalhoInterno editUrl={`/cargos/${id}/edit`} >
          <h3>Cargo</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Nome</div>
              <div className="value">{cargo.nome}</div>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col>
              <h5>Concursos</h5>
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="text-center" width="30px"></th>
                    <th className="text-center" width="30px">#</th>
                    <th>Concurso</th>
                    <th>ano</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    cargo.concursos
                      ? cargo.concursos.map(concurso =>
                        <tr key={concurso.id}>
                          <td className="text-center">
                            <input value={concurso.id} type="checkbox" size="sm" onChange={e => this.selecionarConcursos(e)}/>
                          </td>
                          <td>{concurso.id}</td>
                          <td><Link to={`/concursos/${concurso.id}`}>{concurso.nome}</Link></td>
                          <td>{concurso.ano}</td>
                        </tr>
                      )
                      : null
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = (state) =>
  ({
    cargos: state.cargos,
    login: state.login,
  })


const mapDispatchToProps = dispatch =>
  bindActionCreators({ getCargo, deleteCargos }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CargosShow)