import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css'
import { connect } from 'react-redux'
import Routes from './routes'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Login from './views/Login'

import AreaLateral from './components/AreaLateral'
import Dashboard from './components/Dashboard'
import Configuracoes from './components/Configuracoes'
import { Container } from 'react-bootstrap'

class App extends Component {
  render() {
    const { isAuthenticate/*, expires*/ } = this.props.login
    //const expired = new Date().getTime() > expires

    if (!isAuthenticate /*|| expired*/) {
      return (
        <Login></Login>
      )
    }

    return (
      <Container
        fluid
        className={`
          p-0
          app
          ${this.props.areaLateral.estado === 'CONTRAIDO' ? 'expanded' : ''}
          ${this.props.configuracoes.estado === 'EXPANDIDO' ? 'config' : ''}
        `}>
        <Router>

          <Switch>
            <Route path="/login">
              <Login />
            </Route>
          </Switch>

          <AreaLateral></AreaLateral>
          <Dashboard>
            <Routes></Routes>
          </Dashboard>
          <Configuracoes></Configuracoes>
        </Router>
      </Container>
    )
  }
}

const mapStateToProps = state =>
  ({
    areaLateral: state.areaLateral,
    configuracoes: state.configuracoes,
    login: state.login
  })

export default connect(mapStateToProps)(App)

