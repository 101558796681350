const INITIAL_STATE = {
  assunto: null,
  assuntos: null,
  loadAssuntos: false,
  count: null,
  curPage: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /** GET */
    case 'GET_ASSUNTO_REQUEST':
    case 'GET_ASSUNTOS_REQUEST': {
      return { ...state,loadAssuntos: true }
    }
    case 'GET_ASSUNTO_SUCCESS': {
      return { ...state,loadAssuntos: false, assunto: action.payload }
    }
    case 'GET_ASSUNTOS_SUCCESS': {
      return { ...state,loadAssuntos: false, assuntos: action.payload.data, count: action.payload.count, curPage: action.payload.curPage }
    }
    case 'GET_ASSUNTO_FAILURE':
    case 'GET_ASSUNTOS_FAILURE': {
      return { ...INITIAL_STATE, loadAssuntos: false }
    }

    /** STORE */
    case 'STORE_ASSUNTO_REQUEST': {
      return { ...state, loadAssuntos: true }
    }
    case 'STORE_ASSUNTO_SUCCESS': {
      return { ...state, loadAssuntos: false, successMessageAlert: 'Assunto criado com sucesso.' }
    }
    case 'STORE_ASSUNTO_FAILURE': {
      return { ...state, loadAssuntos: false }
    }

    /** DEFAULT */
    default: {
      return state
    }
  }
}