import React, { Component } from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faSync } from '@fortawesome/free-solid-svg-icons'

class MenuTabela extends Component {
  render() {
    return (
      <Row className="pb-2">
        <Col>
          {
            this.props.atualizarcdids ?
              (
                <Button size="sm" variant="warning" onClick={this.props.atualizarcdids}>
                  <FontAwesomeIcon size="sm" icon={faSync} /> Atualizar CDids
                </Button>
              ) : []
          }
          {
            this.props.remove && this.props.selecionado ?
              (
                <Button size="sm" variant="danger" onClick={this.props.remove} style={{marginLeft: 8}}>
                  <FontAwesomeIcon size="sm" icon={faTrash} /> Remover
                </Button>
              ) : []   
          }
        </Col>
      </Row>
    )
  }
}

export default MenuTabela