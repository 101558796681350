import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getQuestao, deleteQuestoes } from '../../actions/QuestoesAction'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import Loading from '../../components/Loading'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class QuestoesShow extends Component {

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getQuestao(id)
  }


  renderEnunciado() {
    const { enunciado } = this.props.questoes.questao
    var paragrafos = enunciado.split("\n")

    return (
      <div>
        {paragrafos.map((paragrafo, key) => (
          <p key={key} dangerouslySetInnerHTML={{ __html: paragrafo }}>
            {/*paragrafo*/}
          </p>
        ))}
      </div>
    );
  }

  renderRespostas = (respostas) => {
    //const questao = this.props.questoes.questao
    /*return (
      respostas
      ? respostas.map((resposta, index) =>
        (
          <Row className={(index+1) === questao.opcaoCorreta ? 'text-success' : ''} key={resposta.id}>
            <Col className="field"  sm='auto'>
              <div className="value">{String.fromCharCode(65 + index)})</div>
            </Col>
            <Col className="field">
              <div className="value">{resposta.texto}</div>
            </Col>
          </Row>
        ))
      : []
    )*/

    const tipo = this.props.questoes.questao.tipo
    switch (tipo) {
      case 'múltipla escolha 5': {
        return this.renderRespostasMultiplaEscolha5(respostas);
      }
      case 'múltipla escolha 4': {
        return this.renderRespostasMultiplaEscolha4(respostas);
      }
      case 'certo ou errado': {
        return this.renderRespostasCertoOuErrado(respostas);
      }
      default: {
        return this.renderRespostasMultiplaEscolha5(respostas);
      }
    }
  }

  renderRespostasMultiplaEscolha5 = (respostas) => {
    const questao = this.props.questoes.questao
    return (
      <>
        <Row className={(1) === questao.opcaoCorreta ? 'text-success' : ''} key={respostas[0].id}>
          <Col className="field"  sm='auto'>
            <div className="value">{String.fromCharCode(65)})</div>
          </Col>
          <Col className="field">
            <div className="value">{respostas[0].texto}</div>
          </Col>
        </Row>
        <Row className={(2) === questao.opcaoCorreta ? 'text-success' : ''} key={respostas[1].id}>
          <Col className="field"  sm='auto'>
            <div className="value">{String.fromCharCode(66)})</div>
          </Col>
          <Col className="field">
            <div className="value">{respostas[1].texto}</div>
          </Col>
        </Row>
        <Row className={(3) === questao.opcaoCorreta ? 'text-success' : ''} key={respostas[2].id}>
          <Col className="field"  sm='auto'>
            <div className="value">{String.fromCharCode(67)})</div>
          </Col>
          <Col className="field">
            <div className="value">{respostas[2].texto}</div>
          </Col>
        </Row>
        <Row className={(4) === questao.opcaoCorreta ? 'text-success' : ''} key={respostas[3].id}>
          <Col className="field"  sm='auto'>
            <div className="value">{String.fromCharCode(68)})</div>
          </Col>
          <Col className="field">
            <div className="value">{respostas[3].texto}</div>
          </Col>
        </Row>
        <Row className={(5) === questao.opcaoCorreta ? 'text-success' : ''} key={respostas[4].id}>
          <Col className="field"  sm='auto'>
            <div className="value">{String.fromCharCode(69)})</div>
          </Col>
          <Col className="field">
            <div className="value">{respostas[4].texto}</div>
          </Col>
        </Row>
      </>
    )
  }

  renderRespostasMultiplaEscolha4 = (respostas) => {
    const questao = this.props.questoes.questao
    return (
      <>
        <Row className={(1) === questao.opcaoCorreta ? 'text-success' : ''} key={respostas[0].id}>
          <Col className="field"  sm='auto'>
            <div className="value">{String.fromCharCode(65)})</div>
          </Col>
          <Col className="field">
            <div className="value">{respostas[0].texto}</div>
          </Col>
        </Row>
        <Row className={(2) === questao.opcaoCorreta ? 'text-success' : ''} key={respostas[1].id}>
          <Col className="field"  sm='auto'>
            <div className="value">{String.fromCharCode(66)})</div>
          </Col>
          <Col className="field">
            <div className="value">{respostas[1].texto}</div>
          </Col>
        </Row>
        <Row className={(3) === questao.opcaoCorreta ? 'text-success' : ''} key={respostas[2].id}>
          <Col className="field"  sm='auto'>
            <div className="value">{String.fromCharCode(67)})</div>
          </Col>
          <Col className="field">
            <div className="value">{respostas[2].texto}</div>
          </Col>
        </Row>
        <Row className={(4) === questao.opcaoCorreta ? 'text-success' : ''} key={respostas[3].id}>
          <Col className="field"  sm='auto'>
            <div className="value">{String.fromCharCode(68)})</div>
          </Col>
          <Col className="field">
            <div className="value">{respostas[3].texto}</div>
          </Col>
        </Row>
      </>
    )
  }

  renderRespostasCertoOuErrado = (respostas) => {
    const questao = this.props.questoes.questao
    return (
      <>
        <Row className={(1) === questao.opcaoCorreta ? 'text-success' : ''} key={1}>
          <Col className="field"  sm='auto'>
            <div className="value"></div>
          </Col>
          <Col className="field">
            <div className="value">CERTO</div>
          </Col>
        </Row>
        <Row className={(2) === questao.opcaoCorreta ? 'text-success' : ''} key={2}>
          <Col className="field"  sm='auto'>
            <div className="value"></div>
          </Col>
          <Col className="field">
            <div className="value">ERRADO</div>
          </Col>
        </Row>
      </>
    )
  }

  render() {
    const { questao, loadQuestoes } = this.props.questoes
    const { id } = this.props.match.params
    if (loadQuestoes || !questao) {
      return (
        <Loading></Loading>
      )
    }

    return (
      <div>
        <CabecalhoInterno editUrl={`/questoes/${id}/edit`} >
          <h3>Questão</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Concurso</div>
              <div className="value">{questao.concurso ? <Link to={`/concursos/${questao.concurso.id}`}>{questao.concurso.nome}</Link> : ''}</div>
            </Col>
            <Col className="field">
              <div className="title">Ano</div>
              <div className="value">{questao.concurso ? questao.concurso.ano : ''}</div>
            </Col>
            <Col className="field">
              <div className="title">Órgão</div>
              <div className="value">{questao.concurso ? <Link to={`/orgaos/${questao.concurso.orgao.id}`}>{questao.concurso.orgao.nome}</Link> : ''}</div>
            </Col>
            <Col className="field">
              <div className="title">UF</div>
              <div className="value">{questao.concurso ? questao.concurso.orgao.UF : ''}</div>
            </Col>

          </Row>
          <Row className="pb-2">
            <Col className="field">
              <div className="title">Banca</div>
              <div className="value">{questao.concurso ? <Link to={`/bancas/${questao.concurso.banca.id}`}>{questao.concurso.banca.nome}</Link> : ''}</div>
            </Col>
            <Col className="field">
              <div className="title">Matéria</div>
              <div className="value">{questao.assunto ? <Link to={`/materias/${questao.assunto.materia.id}`}>{questao.assunto.materia.nome}</Link> : ''}</div>
            </Col>
            <Col className="field">
              <div className="title">Assunto</div>
              <div className="value">{questao.assunto ? <Link to={`/assuntos/${questao.assunto.id}`}>{questao.assunto.nome}</Link> : ''}</div>
            </Col>
            <Col className="field">
              <div className="title">Tipo</div>
              <div className="value">{questao.tipo}</div>
            </Col>
          </Row>

          <Row className="pb-2">
            <Col className="field">
              <div className="title">Enunciado</div>
              <div className="value">{this.renderEnunciado()}</div>
            </Col>
          </Row>

          <Row className="pb-2">
            <Col className="field">
              <div className="title">Feedback</div>
              <div className="value">{questao.feedback}</div>
            </Col>
          </Row>

          <Row className="pb-5">
            <Col className="field">
              <div className="title">Opções</div>
              {
                this.renderRespostas(questao.respostas)
              }
            </Col>
          </Row>

        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = (state) =>
  ({
    questoes: state.questoes,
    login: state.login,
  })


const mapDispatchToProps = dispatch =>
  bindActionCreators({ getQuestao, deleteQuestoes }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(QuestoesShow)