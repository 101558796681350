import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { storeCargo } from '../../actions/CargosAction'
import Loading from '../../components/Loading'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import { Form, Col, Row, Button, Alert } from 'react-bootstrap'
import { Redirect } from "react-router"

class CargosCreate extends Component {

  state = {
    formNome: '',
    erro: null,
    erroShow: false
  }

  setError = (errorMessage = 'Erro ao tentar criar a cargo.') => {
    this.setState({
      erro: errorMessage,
      erroShow: true
    })
    document.getElementsByClassName('containerInterno')[0].scrollTop = 0
    return false
  }

  formSubmit = (e) => {
    e.preventDefault()

    const { formNome } = this.state
    var errorMessage = ""
    if (!formNome) {
      errorMessage += "* Preencha o nome.<br />"
    }
    if (errorMessage !== "") {
      return this.setError(errorMessage)
    }
    const body = {
      nome: formNome
    }
    this.props.storeCargo(this.props.login.accessToken, body)
  }

  render() {

    const { loadCargos } = this.props.cargos
    const { redirectTo } = this.props.redirect
    const { formNome, erro, erroShow } = this.state

    if (loadCargos) {
      return (
        <Loading></Loading>
      )
    }

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <div>
        <CabecalhoInterno history={this.props.history}>
          <h3>Cargos</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Form onSubmit={this.formSubmit}>
            {erro && erroShow ? <Alert variant="danger" onClick={() => this.setState({erroShow: false})} ><div dangerouslySetInnerHTML={{ __html: erro }} /></Alert> : []}
            <Form.Group as={Row} controlId="formNome">
              <Form.Label column sm="1" className="text-right">
                Nome
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formNome}
                  type="text"
                  name="nome"
                  onChange={e => this.setState({ formNome: e.target.value, erroShow: false })}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={{ span: 11, offset: 1 }}>
                <Button type="submit">Criar</Button>
              </Col>
            </Form.Group>
          </Form>
        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    cargos: state.cargos,
    login: state.login,
    redirect: state.redirect
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ storeCargo }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CargosCreate)