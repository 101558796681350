import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { updateCargo, getCargo } from '../../actions/CargosAction'
import Loading from '../../components/Loading'
import CabecalhoInterno from '../../components/CabecalhoInterno'
import ContainerInterno from '../../components/ContainerInterno'
import { Form, Col, Row, Button, Alert } from 'react-bootstrap'
import { Redirect } from "react-router"

class CargosEdit extends Component {

  state = {
    formNome: '',
    erro: null,
    erroShow: false
  }

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getCargo(id)
  }

  setError = (errorMessage = 'Erro ao tentar criar a cargo.') => {
    this.setState({
      erro: errorMessage,
      erroShow: true
    })
    document.getElementsByClassName('containerInterno')[0].scrollTop = 0
    return false
  }

  formSubmit = (e) => {
    e.preventDefault()
    const { formNome } = this.state
    const { cargo } = this.props.cargos
    const { id } = this.props.match.params
    var errorMessage = ""
    if (!formNome && !cargo.nome) {
      errorMessage += "* Preencha o nome.<br />"
    }
    if (errorMessage !== "") {
      return this.setError(errorMessage)
    }
    const body = {
      nome: formNome ? formNome : cargo.nome
    }
    this.props.updateCargo(this.props.login.accessToken, id, body)
  }

  render() {

    const { cargo, loadCargos } = this.props.cargos
    const { redirectTo } = this.props.redirect
    const { formNome, erro, erroShow } = this.state

    if (loadCargos || !cargo) {
      return (
        <Loading></Loading>
      )
    }

    if (redirectTo) {
      return (
        <Redirect to={redirectTo} />
      )
    }

    return (
      <div>
        <CabecalhoInterno history={this.props.history}>
          <h3>Editar Cargo</h3>
        </CabecalhoInterno>
        <ContainerInterno>
          <Form onSubmit={this.formSubmit}>
            {erro && erroShow ? <Alert variant="danger" onClick={() => this.setState({erroShow: false})} ><div dangerouslySetInnerHTML={{ __html: erro }} /></Alert> : []}
            <Form.Group as={Row} controlId="formNome">
              <Form.Label column sm="1" className="text-right">
                Nome
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  value={formNome ? formNome : cargo.nome}
                  type="text"
                  name="nome"
                  onChange={e => this.setState({ formNome: e.target.value, erroShow: false })}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={{ span: 11, offset: 1 }}>
                <Button type="submit">Editar</Button>
              </Col>
            </Form.Group>
          </Form>
        </ContainerInterno>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    cargos: state.cargos,
    login: state.login,
    redirect: state.redirect
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateCargo, getCargo }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CargosEdit)