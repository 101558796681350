import { API_URL } from "../configs/config";

export const getComentarios = (accessToken) => {
  const headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded",
  });
  return (dispatch) => {
    dispatch({ type: "GET_COMENTARIOS_REQUEST" });
    fetch(`${API_URL}/comentario/aguardando`, {
      method: "get",
      headers,
    })
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_COMENTARIOS_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_COMENTARIOS_FAILURE",
          payload: error,
        })
      );
  };
};

export const aprovarComentario = (accessToken, idComentario) => {
  const headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded",
  });
  return (dispatch) => {
    dispatch({ type: "GET_APROVACAO_REQUEST" });
    fetch(`${API_URL}/comentario/${idComentario}/aprovar`, {
      method: "post",
      headers,
    })
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_APROVACAO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_APROVACAO_FAILURE",
          payload: error,
        })
      );
  };
};

export const desaprovarComentario = (accessToken, idComentario) => {
  const headers = new Headers({
    Authorization: `Basic ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded",
  });
  return (dispatch) => {
    dispatch({ type: "GET_DESAPROVACAO_REQUEST" });
    fetch(`${API_URL}/comentario/${idComentario}/desaprovar`, {
      method: "post",
      headers,
    })
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_DESAPROVACAO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_DESAPROVACAO_FAILURE",
          payload: error,
        })
      );
  };
};
