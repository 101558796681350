import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getQuestoes,
  deleteQuestoes,
  atualizarCDID,
} from "../../actions/QuestoesAction";
import Loading from "../../components/Loading";
import CabecalhoInterno from "../../components/CabecalhoInterno";
import ContainerInterno from "../../components/ContainerInterno";
import Paginate from "../../components/Paginate";
import {
  Table,
  Alert,
  Row,
  Col,
  Form,
  FormControl,
  Button,
  InputGroup,
} from "react-bootstrap";
import MenuTabela from "../../components/MenuTabela";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

class Questoes extends Component {
  state = {
    questoes: [],
    questoesSelecionadas: [],
    enunciado: null,
  };

  componentDidMount() {
    this.props.getQuestoes();
  }

  selecionarQuestoes = (e) => {
    const id = e.target.value;
    const questoesSelecionadas = {
      ...this.state.questoesSelecionadas,
      [id]: e.target.checked,
    };
    this.setState({ questoesSelecionadas: questoesSelecionadas });
  };

  removerQuestoes = (e) => {
    const { questoesSelecionadas } = this.state;
    var questoes = Object.keys(questoesSelecionadas).map((key) => {
      if (questoesSelecionadas[key]) {
        return key;
      }
      return false;
    });
    questoes = questoes.filter(Boolean);
    Swal.fire({
      icon: "warning",
      title: `Deseja realmente remover a${
        questoes.length !== 1 ? "s" : ""
      } quest${questoes.length !== 1 ? "ões" : "ão"}?`,
      text: `${questoes.length} selecionada${
        questoes.length !== 1 ? "s" : ""
      }.`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#dc3545",
      focusCancel: true,
      confirmButtonText: "Ok",
    }).then((e) => {
      if (e.value) {
        this.props.deleteQuestoes(
          this.props.login.accessToken,
          questoes.join()
        );
      }
    });
  };

  atualizarcdids = (e) => {
    this.props.atualizarCDID();
  };

  toPage = (page) => {
    const { procurar } = this.state;
    if (procurar) {
      if (
        procurar
          .toUpperCase()
          .search(/CD[0-9][0-9][0-9][0-9][0-9][0-9][0-9]/) === 0
      ) {
        this.props.getQuestoes({ page, cdid: procurar });
      } else {
        this.props.getQuestoes({ page, enunciado: procurar });
      }
    } else {
      this.props.getQuestoes({ page });
    }
  };

  formSubmit = (e) => {
    e.preventDefault();
    const { procurar } = this.state;
    if (
      procurar.toUpperCase().search(/CD[0-9][0-9][0-9][0-9][0-9][0-9][0-9]/) ===
      0
    ) {
      this.props.getQuestoes({ cdid: procurar });
    } else {
      this.props.getQuestoes({ enunciado: procurar });
    }
  };

  render() {
    const { questoesSelecionadas } = this.state;
    const { questoes, loadQuestoes, count, curPage } = this.props.questoes;

    if (loadQuestoes || !questoes) {
      return <Loading></Loading>;
    }
    var selecionado = Object.keys(questoesSelecionadas).find((key) => {
      return questoesSelecionadas[key];
    });
    return (
      <div>
        <CabecalhoInterno addUrl="/questoes/create">
          <h3>Questões</h3>
        </CabecalhoInterno>
        <ContainerInterno style={{ height: "calc(100vh - 230px)" }}>
          <Row>
            <Col className="text-left">
              {count > 50 ? (
                <Paginate
                  toPage={this.toPage}
                  curPage={curPage}
                  count={count}
                  pages={Math.ceil(+count / 50)}
                />
              ) : (
                []
              )}
            </Col>
            <Col className="text-right">
              {
                <MenuTabela
                  selecionado={selecionado}
                  atualizarcdids={this.atualizarcdids}
                  remove={this.removerQuestoes}
                />
              }
            </Col>
          </Row>

          <Row className="my-2">
            <Col md={"auto"}>
              <Form onSubmit={this.formSubmit}>
                <InputGroup className="mb-2" style={{ width: 400 }}>
                  <FormControl
                    name="enunciado"
                    placeholder="Procurar"
                    value={this.state.procurar ? this.state.procurar : ""}
                    onChange={(e) =>
                      this.setState({ procurar: e.target.value })
                    }
                  />
                  <InputGroup.Append>
                    <Button type={"submit"}>
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </Col>
          </Row>

          {questoes.length > 0 ? (
            <div className="tableContainer tableQuestoes">
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="text-center" width="30px"></th>
                    <th width="30px">Serial</th>
                    <th>Enunciado</th>
                    <th>Tipo</th>
                    <th>Assunto</th>
                    <th>Matéria</th>
                    <th>Concurso</th>
                    <th>Ano</th>
                    <th>Órgão</th>
                    <th>UF</th>
                    <th>Banca</th>
                  </tr>
                </thead>
                <tbody>
                  {questoes.map((questao) => (
                    <tr key={questao.id}>
                      <td className="text-center">
                        <input
                          value={questao.id}
                          type="checkbox"
                          size="sm"
                          onChange={(e) => this.selecionarQuestoes(e)}
                        />
                      </td>
                      <td className="text-center">{questao.cdid}</td>
                      <td>
                        <Link to={`/questoes/${questao.id}`}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: questao.enunciado,
                            }}
                          ></span>
                        </Link>
                      </td>
                      <td>{questao.tipo}</td>
                      <td>
                        {questao.assunto ? (
                          <Link to={`/assuntos/${questao.assunto.id}`}>
                            {questao.assunto.nome}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {questao.assunto ? (
                          <Link to={`/materias/${questao.assunto.materia.id}`}>
                            {questao.assunto.materia.nome}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {questao.concurso ? (
                          <Link to={`/concursos/${questao.concurso.id}`}>
                            {questao.concurso.nome}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{questao.concurso ? questao.concurso.ano : ""}</td>
                      <td>
                        {questao.concurso ? (
                          <Link to={`/orgaos/${questao.concurso.orgao.id}`}>
                            {questao.concurso.orgao.nome}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {questao.concurso ? questao.concurso.orgao.UF : ""}
                      </td>
                      <td>
                        {questao.concurso ? (
                          <Link to={`/bancas/${questao.concurso.banca.id}`}>
                            {questao.concurso.banca.nome}
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <Alert variant="warning">
              Não há questões.{" "}
              <Link to={`/questoes/create`} className="text-primary">
                Clique aqui
              </Link>{" "}
              para cadastrar.
            </Alert>
          )}
        </ContainerInterno>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  questoes: state.questoes,
  login: state.login,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getQuestoes, deleteQuestoes, atualizarCDID }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Questoes);
