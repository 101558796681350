const INITIAL_STATE = {
  redirectTo: null
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'REDIRECT': {
      return { redirectTo: action.payload }
    }

    /** DEFAULT */
    default: {
      return INITIAL_STATE
    }
  }
}